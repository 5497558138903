import React, { useState,useEffect } from "react";
import styled from "styled-components";
import usage1 from "../images/usage1.png";
import usage2 from "../images/usage2.png";
import usage3 from "../images/usage3.png";

function useWindowSize(){
  const [size,setSize] = useState([window.innerHeight,window.innerWidth]);
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerHeight,window.innerWidth]);
    };
    window.addEventListener("resize",handleResize);
    return () => {
      window.removeEventListener("resize",handleResize);
    };
  }, []);
  return size;
}

function Use() {
  
  const [height,width] = useWindowSize();

  if(width<1036)
  {
    return (
      <Usage id="3">
      <div className="how_to_use" style={{disply:"flex",flexDirection:"columns"}}>
        <div style={{ display: "flex" }}>
          <p style={{ textAlign: "center", fontSize: "22px" }}>
             <h3 style={{color:"#007AFF"}} >Step 1:</h3>
             <h3>Select the product</h3>
          </p>
          <div className="parentusageimages">
            <img className="usageimages" src={usage1} alt="usage1" />
          </div>
  
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ textAlign: "center", fontSize: "22px" }}>
            <h3 style={{color:"#007AFF"}}>Step 2:</h3>
            <h3>Select your image</h3>
          </p>
          <div className="parentusageimages">
            <img className="usageimages" src={usage2} alt="usage2" />
          </div>
  
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ textAlign: "center", fontSize: "22px" }}>
             <h3 style={{color:"#007AFF"}}>Step 3:</h3>
             <h3>Virtually try-on clothes</h3>
          </p>
          <div className="parentusageimages">
            <img className="usageimages" src={usage3} alt="usage3" />
          </div>
  
        </div>
        </div>
      </Usage>
    );
  }

  return (
    <Usage id="3">
    {/* <div className="how_to_use" style={{disply:"flex",flexDirection:"columns"}}> */}
      <div style={{ display: "flex" }}>
        <p style={{ textAlign: "center", fontSize: "22px" }}>
           <h3 style={{color:"#007AFF"}} >Step 1:</h3>
           <h3>Select the product</h3>
        </p>
        <div className="parentusageimages">
          <img className="usageimages" src={usage1} alt="usage1" />
        </div>

      </div>
      <div style={{ display: "flex" }}>
        <p style={{ textAlign: "center", fontSize: "22px" }}>
          <h3 style={{color:"#007AFF"}}>Step 2:</h3>
          <h3>Select your image</h3>
        </p>
        <div className="parentusageimages">
          <img className="usageimages" src={usage2} alt="usage2" />
        </div>

      </div>
      <div style={{ display: "flex" }}>
        <p style={{ textAlign: "center", fontSize: "22px" }}>
           <h3 style={{color:"#007AFF"}}>Step 3:</h3>
           <h3>Virtually try-on clothes</h3>
        </p>
        <div className="parentusageimages">
          <img className="usageimages" src={usage3} alt="usage3" />
        </div>

      </div>
      {/* </div> */}
    </Usage>
  );
}

export default Use;

const Usage = styled.div`
  display: flex;
  ${'' /* flex-wrap: wrap; */}
  justify-content: space-around;
  width: 100%;
  .usageimages {
    width: 200px;
    height: 430px;
    margin: 10px;
    padding: 10px;
  }
  .parentusageimages {
    ${'' /* background: #007aff; */}
    width: 100%;
    height: 100%;
    ${'' /* border-radius: 50%; */}
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`;
