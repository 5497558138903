import React, { useState, useLayoutEffect, useEffect } from "react";
import styled from "styled-components";
import { Slider } from "@mui/material";

function Filterprice() {
  const [val, setval] = useState([250, 3550]);
  const updateVal = (e, item) => {
    setval(item);
  };
  return (
    <Container>
      <Slider value={val} max={10000} onChange={updateVal} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <input value={"₹" + val[0]} className="filtervaluediv" />
            <h1 style={{ margin: "0", color: "#007aff" }}>-</h1>
            <input value={"₹" + val[1]} className="filtervaluediv" />
          </div>
          <div className="filterbtn">Filter</div>
        </div>
      </div>
    </Container>
  );
}

export default Filterprice;

const Container = styled.div`
  width: 60%;
  margin-left: 70px;
  .filtervaluediv {
    border: 2px solid #007aff;
    background: white;
    color: #007aff;
    border-radius: 5px;
    width: 60px;
    height: 30px;
    text-align: center;
    vertical-align: center;
    font-size: 16px;
  }
  .filterbtn {
    color: white;
    background: #007aff;
    padding: 8px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
`;
