import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Navbar from "./Navbar";
import Welcome from "./Welcome";
import Better from "./Better";
import Use from "./Use";
import Feedback from "./Feedback";
import Getintouch from "./Getintouch";
import Footer from "./Footer";
import perfectfit from "../images/perfectfit.gif";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import $ from "jquery";

import {
  auth,
  signInWithEmailAndPassword1,
  signInWithGoogle1,
  logout,
} from "../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import { updateDoc, deleteDoc, doc } from "firebase/firestore/lite";

function Home() {
  var a = document.querySelector(".uparrow");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  $(document).ready(function () {
    $(window).scroll(function () {
      // var x = window.matchMedia("(max-width: 780px)");
      // if (x.matches) {
      //   var a = document.querySelector("#navbarscrollback");
      //   a.style.backgroundColor = "crimson";
      // }
      var a = document.querySelector(".uparrow");
      if (this.scrollY > 30) {
        var a = document.querySelector(".uparrow");
        if (a) {
          a.style.display = "block";
        }
      }
      if (this.scrollY < 30) {
        var a = document.querySelector(".uparrow");
        if (a) {
          a.style.display = "none";
        }
      }
    });
  });

  return (
    <Container>
      <Navbar id="1" />

      <Welcome />
      <br />
      <h1 style={{ width: "100%", textAlign: "center", fontSize: "60px" }}>
        How to use
      </h1>
      <Use />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Better />
      <br />
      <br />
      <Footer />
      <a href="#" style={{ textDecoration: "none", color: "white" }}>
        <ArrowUpwardIcon className="uparrow" />
      </a>
    </Container>
  );
}

export default Home;

const Container = styled.div`
  margin: 0px;
  color: black;
  .uparrow {
    color: white;
    background: #007aff;
    border-radius: 5px;
    position: absolute;
    bottom: 50px;
    right: 50px;
    position: fixed;
    cursor: pointer;
    font-size: 40px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease-in-out;
  }

  @media screen and (max-width: 475px) {
    .uparrow {
      display: none;
    }
    width: 475px;
    display: inline-block;
  }
`;
