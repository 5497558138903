import React, { useState,useEffect } from "react";
import styled from "styled-components";
import perfectfit1 from "../images/perfectfit.gif";
import allinone from "../images/allinone.gif";
import ultra from "../images/ultra-realistic.gif";
import democratic from "../images/democratic.gif";
import default1 from "../images/default.png";

function useWindowSize(){
  const [size,setSize] = useState([window.innerHeight,window.innerWidth]);
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerHeight,window.innerWidth]);
    };
    window.addEventListener("resize",handleResize);
    return () => {
      window.removeEventListener("resize",handleResize);
    };
  }, []);
  return size;
}


function Better() {
  const [imgaddress, setimgaddress] = useState(default1);
  const [height,width] = useWindowSize();
  const imagechanger = (c) => {
    // document.getElementById("setimage").src = `url("../images/perfectfit.gif")`;
    // document.querySelector("#setimage").src = "../images/perfectfit.gif";
    setimgaddress(perfectfit1);
    if (c == 1) {
      setimgaddress(democratic);
      if(width<1030)
      window.scrollBy(0, 1000);
    }
    if (c == 2) {
      setimgaddress(ultra);
      if(width<1030)
      window.scrollBy(0, 800);
    }
    if (c == 3) {
      setimgaddress(perfectfit1);
      if(width<1030)
      window.scrollBy(0, 600);
    }
    if (c == 4) {
      setimgaddress(allinone);
      if(width<1030)
      window.scrollBy(0, 500);
    }
  };
  return (
    <Better1 id="2">
      <h1 className="betterhead">
        Why <span style={{ color: "#007aff" }}>Tvish</span> is{" "}
        <span style={{ color: "#007aff" }}>better</span>?
      </h1>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div onClick={() => imagechanger(1)} className="betterdiv">
            <h1>Democratic</h1>
            <p>We enable Smartphone based Tryon.</p>
            <br />
            <h1 className="betternumber">01</h1>
          </div>
          <div onClick={() => imagechanger(2)} className="betterdiv">
            <h1>Ultra-Realistic</h1>
            <p>High Quality 3D models and 2D masks having greater appeal.</p>
            <h1 className="betternumber">02</h1>
          </div>
          <div onClick={() => imagechanger(3)} className="betterdiv">
            <h1>Perfect Fit</h1>
            <p>
              Dimensional model of each user to find the best fit of clothes.
            </p>
            <h1 className="betternumber">03</h1>
          </div>
          <div onClick={() => imagechanger(4)} className="betterdiv">
            <h1>All in One</h1>
            <p>Building tryon capibilities from clothes to furniture.</p>
            <h1 className="betternumber">04</h1>
          </div>
        </div>
        <div className="whitespace">
          <img id="setimage" src={imgaddress} alt="hi" />
        </div>
      </div>
    </Better1>
  );
}

export default Better;

const Better1 = styled.div`
  .betterhead {
    font-size: 70px;
    text-align: center;
  }
  .betterdiv {
    background: rgba(0, 122, 255, 0.05);
    width: 350px;
    border-radius: 10px;
    height: 150px;
    padding-left: 20px;
    padding-bottom: 10px;
    display: inline-block;
    margin-top: 12px;
    margin-bottom: 12px;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    h1 {
      font-size: 35px;
      color: #007aff;
      margin-top: 20px;
    }
    p {
      font-size: 19px;
      color: #007aff;
      margin: 0;
    }
  }
  .betterdiv:hover {
    background: #007aff;

    h1 {
      background: white;
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
    p {
      background: white;
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }

    .betternumber {
      background: white;
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
  }
  .betternumber {
    position: relative;
    left: 80%;
    bottom: 140px;
    width: 40px;
    margin-right: 0;
  }
  .whitespace {
    width: 650px;
    height: 650px;
    // background-color: white;
    margin: 50px;
  }
  #setimage {
    margin: 0;
    padding: 0;
    width: 650px;
    height: 650px;
  }
  @media screen and (max-width: 880px) {
    .betterhead {
      font-size: 200%;
    }
  }
  @media screen and (max-width: 970px) {
    .whitespace {
      width: 400px;
      height: 400px;
    }
    #setimage {
      width: 400px;
      height: 400px;
    }
  }
  @media screen and (max-width: 830px) {
    .betterdiv {
      width: 200px;
      h1 {
        font-size: 23px;
      }
      p {
        font-size: 15px;
      }
    }
    .betternumber {
      bottom: 125px;
    }
  }
  @media screen and (max-width: 670px) {
    .whitespace {
      width: 300px;
      height: 300px;
    }
    #setimage {
      width: 300px;
      height: 300px;
    }
    .betterdiv {
      width: 350px;
      h1 {
        font-size: 35px;
      }
      p {
        font-size: 19px;
      }
    }
    .betternumber {
      bottom: 140px;
    }
  }
`;
