import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tshirt from "../images/010567_1 1.svg";
import heart1 from "../images/heart_outline.svg";
import heart2 from "../images/coolicon-1.svg";
import heart3 from "../images/heart_fill_red.svg";
import roundblue from "../images/Group 344.svg";
import { Link, useHistory } from "react-router-dom";

//auth and db firebase imports
import { db } from "../firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore/lite";
import {
  auth,
  signInWithEmailAndPassword1,
  signInWithGoogle1,
  logout,
} from "../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";

function Productimage({ pname, pprice, mainimg, productid }) {
  const [heart, setheart] = useState(heart1);
  const [a, seta] = useState(0);

  //loginorprofile starts
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);

  const [islogin, setislogin] = useState(false);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      setislogin(false);
      return;
    }
    if (user) {
      setislogin(true);
    }
    if (error) {
      setislogin(false);
    }
  }, [user, loading]);

  const [name, setName] = useState("");
  const [userid, setuserid] = useState("");
  const fetchUserName = async () => {
    try {
      const userscollectionref22 = collection(db, "usersdatafromwebsite");
      // const query = await db
      //   .collection("usersdatafromwebsite")
      //   .where("uid", "==", user?.uid)
      //   .get();
      const query1 = query(userscollectionref22, where("uid", "==", user?.uid));
      // const data = await query1.docs[0].data();
      const dataSnapshot = await getDocs(query1);
      setName(dataSnapshot.docs[0].data().name);
      setuserid(dataSnapshot.docs[0].id);
      setislogin(true);
      // const data = await getDocs(collectionRef);
      // setproducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  };
  useEffect(() => {
    if (loading) {
      setislogin(false);
    }
    if (!user) {
      setislogin(false);
    }
    if (user) {
      console.log(user);
      setislogin(true);
      fetchUserName();
    }
  }, [user, loading]);
  useEffect(() => {
    if (name === "") {
      fetchUserName();
    }
  }, [name]);

  //loginorprofile ends
  // const [b, setb] = useState(0);
  var b = 0;
  //wishlist database starts
  const [wishlist, setwishlist] = useState([]);
  const fetchuserwishlist = async () => {
    try {
      const userscollectionref = collection(db, "usersdatafromwebsite");
      const query1 = query(userscollectionref, where("uid", "==", user?.uid));

      const dataSnapshot = await getDocs(query1);
      setwishlist(dataSnapshot.docs[0].data().wishlist);
      b = 0;
      dataSnapshot.docs[0].data().wishlist.map((productidfromarray) => {
        if (productid === productidfromarray) {
          setheart(heart3);
          seta(1);
          b = 1;
        }
      });
      if (b == 0) {
        setheart(heart1);
        seta(0);
      }

      setislogin(true);
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    fetchuserwishlist();
    if (!user) {
      setheart(heart1);
      seta(0);
      setislogin(false);
    }
  }, [wishlist, user]);
  const changetheloveicon = () => {
    if (a == 1) {
      // setheart(heart1);
      // seta(0);
      var newnotes = wishlist;
      newnotes = newnotes.filter((noteItem) => {
        return noteItem !== productid;
      });
      // setwishlist(newnotes);
      const updatewishlistinfirebase = async () => {
        const usercollectionref = doc(db, "usersdatafromwebsite", userid);
        await updateDoc(usercollectionref, { wishlist: newnotes });
      };
      updatewishlistinfirebase();
    } else {
      // setheart(heart3);
      // seta(1);
      var dummywishlist = [...wishlist, productid];
      // setwishlist(dummywishlist);
      const updatewishlistinfirebase = async () => {
        const usercollectionref = doc(db, "usersdatafromwebsite", userid);

        await updateDoc(usercollectionref, { wishlist: dummywishlist });
      };
      updatewishlistinfirebase();
    }
  };
  //wishlist database ends

  return (
    <Container>
      {!islogin && (
        <div className="section1imageschild">
          <Link style={{ textDecoration: "none" }} to={`/product/${productid}`}>
            <div
              style={{
                padding: "20px",
                backgroundColor: "#F2F8FF",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img style={{ width: "180px", height: "231px" }} src={mainimg} />
            </div>
          </Link>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <p
                style={{
                  margin: "0",
                  color: "rgba(80, 80, 80, 0.6)",
                  width: "160px",
                }}
              >
                {pname}
              </p>
              <h3 style={{ margin: "0", color: "rgba(80, 80, 80, 0.9)" }}>
                ₹{pprice}
              </h3>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{
                  width: "35px",
                  height: "35px",
                }}
                src={heart}
                // onClick={() => {
                //   if (a == 1) {
                //     setheart(heart3);
                //     seta(0);
                //   } else {
                //     setheart(heart1);
                //     seta(1);
                //   }
                // }}
                onClick={() =>
                  alert(
                    "We are Sorry! you need to first have an account for using Wishlist."
                  )
                }
              />
              <img
                style={{ width: "35px", height: "35px", margin: "5px" }}
                src={roundblue}
                onClick={() =>
                  alert(
                    "We are Sorry! you need to first have an account for using Try-on."
                  )
                }
              />
            </div>
          </div>
        </div>
      )}
      {islogin && (
        <div className="section1imageschild">
          <Link style={{ textDecoration: "none" }} to={`/product/${productid}`}>
            <div
              style={{
                padding: "20px",
                backgroundColor: "#F2F8FF",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img style={{ width: "180px", height: "231px" }} src={mainimg} />
            </div>
          </Link>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <p
                style={{
                  margin: "0",
                  color: "rgba(80, 80, 80, 0.6)",
                  width: "160px",
                }}
              >
                {pname}
              </p>
              <h3 style={{ margin: "0", color: "rgba(80, 80, 80, 0.9)" }}>
                ₹{pprice}
              </h3>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{
                  width: "35px",
                  height: "35px",
                }}
                src={heart}
                onClick={() => changetheloveicon()}
              />
              <img
                style={{ width: "35px", height: "35px", margin: "5px" }}
                src={roundblue}
              />
            </div>
          </div>
        </div>
      )}
    </Container>
  );
}

export default Productimage;

const Container = styled.div`
  margin: 40px;
`;
