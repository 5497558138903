import React, { useState, useLayoutEffect, useEffect } from "react";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo1 from "../images/tvish logo.png";
import profileimg from "../images/Profile.svg";
import { Link, useHistory } from "react-router-dom";
import tshirt from "../images/010567_1 1.svg";
import smalltshirt from "../images/tshirtsmall.svg";
import heart1 from "../images/heart_outline.svg";
import heart2 from "../images/heart_fill_red.svg";

import heartblack from "../images/heart_outline_black.svg";
import star from "../images/Star.svg";
import navheart from "../images/heart_outline1.svg";
import navbookmark from "../images/Vector1.svg";
import profileimg1 from "../images/Profile.svg";
import profileimg2 from "../images/Profileblue.svg";
import ShareIcon from "@mui/icons-material/Share";
import roundblue from "../images/Group 344.svg";
import Productimage from "./Productimage";
import Footer2 from "./Footer2";
import Footer from "./Footer";
import tryonimg from "../images/Scan.svg";
import buynowimg from "../images/Bag 3.svg";
import { useParams } from "react-router-dom";
// import { db } from "../firebase-config";
// import { collection, getDocs, addDoc } from "firebase/firestore/lite";

//auth and db firebase imports
import { db } from "../firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
} from "firebase/firestore/lite";
import {
  auth,
  signInWithEmailAndPassword1,
  signInWithGoogle1,
  logout,
} from "../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import { updateDoc, deleteDoc, doc } from "firebase/firestore/lite";

function Product() {
  const [isMobile, setIsMobile] = useState(false);
  var { productid } = useParams();
  // useLayoutEffect(() => {
  //   window.scrollTo(0, 0);
  // });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [products, setproducts] = useState([]);
  const [relatedproducts, setrelatedproducts] = useState([]);
  const [mainimgbig, setmainimgbig] = useState("");
  // const [productrequired, setproductrequired] = useState([]);
  const [prindex, setprindex] = useState(0);
  const collectionRef = collection(db, "ClothesData");
  useEffect(() => {
    const getproducts = async () => {
      const data = await getDocs(collectionRef);
      var requireddata = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setproducts(requireddata);
      requireddata.map((product, index) => {
        if (product.id === productid) {
          setprindex(index);
          setmainimgbig(product.images[0]);
        }
      });
      requireddata = requireddata.filter((product) => {
        return product.id !== productid;
      });
      setrelatedproducts(requireddata);
    };
    getproducts();
    window.scrollTo(0, 0);
  }, [productid]);

  const [loadmorevalue, setloadmorevalue] = useState(4);

  const [profileimg, setprofileimg] = useState(profileimg1);
  const [islogin, setislogin] = useState(false);
  useEffect(() => {
    if (!islogin) {
      setprofileimg(profileimg1);
    }
  }, [islogin]);

  const changeiconprof = (vi) => {
    if (profileimg == profileimg1 || profileimg == profileimg2) {
      if (vi == 1) {
        setprofileimg(profileimg2);
      } else {
        setprofileimg(profileimg1);
      }
    }
    if (vi == 1) {
      const list = document.querySelector(".submenulist");
      if (list) {
        list.classList.remove("hidden3");
      }
    } else {
      const list = document.querySelector(".submenulist");
      if (list) {
        list.classList.add("hidden3");
      }
    }
  };
  const [content, setcontent] = useState(
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"
  );
  const clickHandler = () => {
    setIsMobile(!isMobile);
  };
  const btnclicked = (p) => {
    if (p == 3) {
      document.querySelector(".videocontent").classList.remove("hidden1");
      document.querySelector(".desccontent").classList.add("hidden1");
    } else {
      document.querySelector(".videocontent").classList.add("hidden1");
      document.querySelector(".desccontent").classList.remove("hidden1");
    }
  };

  //loginorprofile starts
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      setislogin(false);
      return;
    }
    if (user) {
      setislogin(true);
    }
    if (error) {
      setislogin(false);
    }
  }, [user, loading]);

  const [name, setName] = useState("");
  const [userid, setuserid] = useState("");
  const [firstnametoshow, setfirstnametoshow] = useState("");
  const fetchUserName = async () => {
    try {
      const userscollectionref22 = collection(db, "usersdatafromwebsite");
      // const query = await db
      //   .collection("usersdatafromwebsite")
      //   .where("uid", "==", user?.uid)
      //   .get();
      const query1 = query(userscollectionref22, where("uid", "==", user?.uid));
      // const data = await query1.docs[0].data();
      const dataSnapshot = await getDocs(query1);
      setName(dataSnapshot.docs[0].data().name);
      setuserid(dataSnapshot.docs[0].id);
      var firstnametoshow1 = dataSnapshot.docs[0].data().name;
      firstnametoshow1 = firstnametoshow1.split(" ");
      firstnametoshow1 = firstnametoshow1[0];
      setfirstnametoshow(firstnametoshow1);
      if (dataSnapshot.docs[0].data().authProvider === "google") {
        setprofileimg(dataSnapshot.docs[0].data().photo);
      }
      setislogin(true);
      // const data = await getDocs(collectionRef);
      // setproducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  };
  useEffect(() => {
    if (loading) return;
    if (!user) {
      setislogin(false);
    }
    if (user) {
      console.log(user);
      setislogin(true);
      fetchUserName();
    }
  }, [user, loading]);
  useEffect(() => {
    if (name === "") {
      fetchUserName();
    }
  }, [name]);

  //loginorprofile ends

  const [heart, setheart] = useState(heartblack);
  const [a, seta] = useState(0);
  const [b, setb] = useState(0);
  //wishlist database starts
  const [wishlist, setwishlist] = useState([]);
  const fetchuserwishlist = async () => {
    try {
      const userscollectionref = collection(db, "usersdatafromwebsite");
      const query1 = query(userscollectionref, where("uid", "==", user?.uid));

      const dataSnapshot = await getDocs(query1);
      setwishlist(dataSnapshot.docs[0].data().wishlist);
      setb(0);
      dataSnapshot.docs[0].data().wishlist.map((productidfromarray) => {
        if (productid === productidfromarray) {
          setheart(heart2);
          seta(1);
          setb(1);
        }
      });
      if (b == 0) {
        setheart(heartblack);
        seta(0);
      }
      setislogin(true);
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (user) {
      fetchuserwishlist();
    }
  }, [wishlist]);

  const changetheloveicon = () => {
    if (a == 1) {
      // setheart(heart1);
      // seta(0);
      var newnotes = wishlist;
      newnotes = newnotes.filter((noteItem) => {
        return noteItem !== productid;
      });
      setwishlist(newnotes);
      const updatewishlistinfirebase = async () => {
        const usercollectionref = doc(db, "usersdatafromwebsite", userid);
        await updateDoc(usercollectionref, { wishlist: newnotes });
      };
      updatewishlistinfirebase();
    } else {
      // setheart(heart3);
      // seta(1);
      var dummywishlist = [...wishlist, productid];
      setwishlist(dummywishlist);
      const updatewishlistinfirebase = async () => {
        const usercollectionref = doc(db, "usersdatafromwebsite", userid);

        await updateDoc(usercollectionref, { wishlist: dummywishlist });
      };
      updatewishlistinfirebase();
    }
  };
  //wishlist database ends
  const copy = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    alert("Link Copied!");
  };

  return (
    <Container>
      <Navbar1>
        <Link to="/">
          <img
            style={{ width: "90px", height: "50px", marginLeft: "2%" }}
            src={logo1}
          />
        </Link>
        <div style={{ display: "flex" }}>
          <ul className={isMobile ? "navfeat active" : "navfeat"}>
            {/* <li className="lists">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li> */}
            <li className="lists">
              <Link to="/aboutus" className="nav-link">
               Home
              </Link>
            </li>
            <li className="lists">
              <Link to="/" className="nav-link">
                Shop
              </Link>
            </li>
            <li className="lists">
              <Link to="/team" className="nav-link">
                Team
              </Link>
            </li>
            <li className="lists">
              <Link to="#" className="nav-link">
                Contact
              </Link>
            </li>
           
            <li className="lists">
              <Link to="#" className="nav-link">
               Join Waitlist
              </Link>
            </li>

          </ul>
        </div>
        {!islogin && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              onClick={() =>
                alert(
                  "We are Sorry! you need to first have an account for using Wishlist."
                )
              }
              src={navheart}
            />
            {/* <img src={navbookmark} /> */}
            <ul style={{ paddingLeft: "0" }}>
              <li className="lists">
                <Link
                  onMouseEnter={() => {
                    changeiconprof(1);
                  }}
                  onMouseLeave={() => {
                    changeiconprof(2);
                  }}
                  to="/"
                  className="nav-link2"
                >
                  <img src={profileimg} />
                  Login
                </Link>
              </li>
            </ul>
          </div>
        )}
        {islogin && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link to="/wishlistpage">
              <img src={navheart} />
            </Link>
            {/* <img onClick={logout} src={navbookmark} /> */}
            <ul style={{ paddingLeft: "0" }}>
              <li className="lists">
                <a
                  onMouseEnter={() => {
                    changeiconprof(1);
                  }}
                  onMouseLeave={() => {
                    changeiconprof(2);
                  }}
                  className="nav-link2"
                  // onClick={() => alert(name)}
                  // onClick={logout}
                >
                  <img
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "20px",
                      marginRight: "5px",
                    }}
                    src={profileimg}
                  />
                  {firstnametoshow}
                  <ul className="submenulist hidden3">
                    <li>Settings</li>
                    <li
                      onClick={() => {
                        logout();
                        setislogin(false);
                      }}
                    >
                      Logout
                    </li>
                  </ul>
                </a>
              </li>
            </ul>
          </div>
        )}
        <div className="mobile-menu-icon" onClick={clickHandler}>
          {isMobile ? (
            <CloseIcon
              style={{
                cursor: "pointer",
                width: "40px",
                height: "40px",
                border: "1px solid white",
                borderRadius: "10px",
              }}
            />
          ) : (
            <MenuIcon
              style={{
                cursor: "pointer",
                width: "40px",
                height: "40px",
                border: "1px solid white",
                borderRadius: "10px",
              }}
            />
          )}
        </div>
      </Navbar1>
      <Product1>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap-reverse",
            justifyContent: "space-between",
          }}
        >
          <div className="smallimagesdiv">
            {/* <img className="smallimage" src={smalltshirt} />
            <img className="smallimage" src={smalltshirt} />
            <img className="smallimage" src={smalltshirt} /> */}
            {products.length > 0 &&
              products[prindex].images.map((image) => (
                <img
                  onClick={() => setmainimgbig(image)}
                  className="smallimage"
                  src={image}
                />
              ))}
          </div>
          {products.length > 0 && (
            <img
              className="bigmainimage"
              style={{
                width: "400px",
                height: "450px",
                marginLeft: "60px",
                marginBottom: "15px",
              }}
              src={mainimgbig}
            />
          )}
        </div>
        {products.length > 0 && (
          <div className="productdiv2">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                style={{
                  color: "#505050",
                  fontSize: "22px",
                  marginTop: "20px",
                }}
              >
                Brand : {products[prindex].brand}
              </p>
              <div>
                <img
                  style={{
                    width: "35px",
                    height: "35px",
                    marginRight: "20px",
                  }}
                  src={heart}
                  onClick={() => {
                    if (islogin) {
                      changetheloveicon();
                    }
                  }}
                />
                <ShareIcon
                  onClick={copy}
                  style={{ width: "35px", height: "35px" }}
                />
              </div>
            </div>
            <h2
              style={{
                color: "#505050",
                fontSize: "30px",
                fontWeight: "500",
                width: "400px",
              }}
            >
              {products[prindex].name}
            </h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "8px 5px",
              }}
            >
              <p
                style={{
                  fontSize: "25px",
                  fontWeight: "500",
                  color: "#505050",
                }}
              >
                {products[prindex].rating}
              </p>
              <img src={star} style={{ margin: "5px", marginLeft: "0" }} />
            </div>
            <p
              style={{ fontSize: "30px", color: "#505050", fontWeight: "bold" }}
            >
              ₹{products[prindex].price}
            </p>

            <p
              style={{
                color: " rgba(80, 80, 80, 0.7)",
                fontSize: "22px",
                margin: "10px 0",
              }}
            >
              Details
            </p>

            <p style={{ width: "450px", fontSize: "20px" }}>
              {products[prindex].fabric}
            </p>
            <a href={products[prindex].webview}>
              <div className="buybtn">
                Buy now{" "}
                <img
                  style={{
                    width: "22px",
                    height: "22px",
                    position: "relative",
                    top: "3px",
                  }}
                  src={buynowimg}
                />
              </div>
            </a>
            <div className="trybtn">
              Try-on{" "}
              <img
                style={{
                  width: "22px",
                  height: "22px",
                  position: "relative",
                  top: "3px",
                }}
                src={tryonimg}
              />
            </div>
          </div>
        )}
      </Product1>
      {/* <Description>
        <div className="btnsdesc">
          <div
            onClick={() => {
              btnclicked(1);
            }}
            className="childbtnsdesc"
          >
            Description
          </div>
          <div
            onClick={() => {
              btnclicked(2);
            }}
            className="childbtnsdesc"
          >
            FAQ's
          </div>
          <div
            onClick={() => {
              btnclicked(3);
            }}
            className="childbtnsdesc"
          >
            Video
          </div>
          <div
            onClick={() => {
              btnclicked(4);
            }}
            className="childbtnsdesc"
          >
            Reviews
          </div>
        </div>
        <div className="desccontent">{content}</div>
        <div className="videocontent hidden1"></div>
      </Description> */}
      <br />
      <br />
      <br />
      <br />
      <br />

      <hr
        style={{
          width: "100px",
          margin: "auto",
          borderWidth: "0",
          color: "rgba(80, 80, 80, 0.4)",
          height: "2px",
          backgroundColor: "rgba(80, 80, 80, 0.4)",
        }}
      />
      <h1 style={{ textAlign: "center", color: "#505050", fontSize: "40px" }}>
        Related Products
      </h1>
      <Sections1>
        <div className="section1images">
          {relatedproducts.slice(0, loadmorevalue).map((product) => (
            <Productimage
              pname={product.name}
              pprice={product.price}
              mainimg={product.images[0]}
              productid={product.id}
            />
          ))}
        </div>
      </Sections1>
      <br />
      <hr className="hori1" />
      <div
        className="loadmore"
        onClick={() => setloadmorevalue(loadmorevalue + 4)}
      >
        Load more
      </div>
      <br />
      <Footer />
    </Container>
  );
}

export default Product;

const Container = styled.div`
  * {
    margin: 0;
  }

  @media screen and (max-width: 475px) {
    width: 475px;
    // display: inline-block;
  }
  .loadmore {
    padding: 10px 15px;
    border: 2px solid #505050;
    color: #505050;
    display: inline-block;
    position: relative;
    left: 46%;
    bottom: 27px;
    background: white;
    cursor: pointer;
    transition: 0.3s ease-out;
    border-radius: 5px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  }
  .hori1 {
    width: 80%;
    margin: 0 auto;
  }
  .loadmore:hover {
    .hori1 {
      color: #007aff;
    }
    border: 2px solid #007aff;
    color: #007aff;
  }
  @media screen and (max-width: 550px) {
    .loadmore {
      left: 40%;
    }
  }
`;

const Sections1 = styled.div`
  margin: 120px;

  .section1images {
    display: flex;
    justify-content: space-around;
    margin: 60px;
    flex-wrap: wrap;
  }
`;

const Description = styled.div`
  background: #f2f8ff;
  border-radius: 20px;
  width: 90%;
  margin: 100px auto;
  padding: 50px;

  .btnsdesc {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  .childbtnsdesc {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 120px;
    text-align: center;
    background-color: white;
    color: black;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease-out;
    margin: 10px;
  }
  .childbtnsdesc:hover {
    background-color: #007aff;
    color: white;
  }
  .childbtndesc1 {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 120px;
    text-align: center;
    background-color: #007aff;
    color: white;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease-out;
  }
  .childbtnsdesc1:hover {
    background-color: white;
    color: #007aff;
  }
  .desccontent {
    width: 80vw;
    margin: 50px auto;
  }
  .hidden1 {
    display: none;
  }
  .videocontent {
    background: white;
    width: 70vw;
    height: 400px;
    border-radius: 10px;
    margin: 50px auto;
  }
`;

const Product1 = styled.div`
  padding-top: 180px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding-left: 100px;
  flex-wrap: wrap;
  .smallimage {
    width: 100px;
    height: 100px;
    margin: 10px;
    cursor: pointer;
    transition: all 0.3s ease-out;
    border: 2px solid white;
    border-radius: 10px;
  }
  .smallimage:hover {
    border: 2px solid #007aff;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  }
  .productdiv2 {
    margin-left: 200px;
  }
  .trybtn {
    display: inline-block;
    padding: 10px 25px;
    border: 2px solid #007aff;
    background-color: #007aff;
    color: white;
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 22px;
  }
  .buybtn {
    display: inline-block;
    padding: 10px 20px;
    border: 2px solid #007aff;
    background-color: white;
    color: #007aff;
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 0px;
    font-size: 22px;
  }
  .bigmainimage {
    width: 400px;
    height: 400px;
  }
  .smallimagesdiv {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  @media screen and (max-width: 1338px) {
    .productdiv2 {
      margin-left: 30px;
      margin-top: 60px;
    }
    padding-left: 10px;
  }
  @media screen and (max-width: 600px) {
    padding-left: 5px;
    .bigmainimage {
      width: 250px;
      height: 250px;
    }
  }
  @media screen and (max-width: 685px) {
    .smallimagesdiv {
      flex-direction: row;
    }
  }
`;

const Navbar1 = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // padding-left: 80px;
  // padding-right: 30px;
  padding: 5px 50px;
  position: fixed;
  top: 0;
  width: 95%;
  border-bottom: 2px solid #50505026;
  z-index: 1000;
  height: 130px;
  .navfeat {
    display: flex;
  }
  .lists {
    list-style: none;
    margin: 25px;
    font-size: 20px;
    font-weight: 600;
    line-height: 34.05px;
    color: #1938a7;
  }

  .mobile-menu-icon {
    display: none;
  }
  .nav-link {
    color: rgba(80, 80, 80, 0.5);
    text-decoration: none;
    // padding: 1rem;
    // padding: 5px 10px;
    // border: 3px solid white;
    // border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 18px;
  }
  .nav-link:hover {
    // border: 3px solid #007aff;
    // color: white;
    // background: #007aff;
    // border-radius: 5px;
    color: black;
  }
  .nav-link1 {
    color: #007aff;
    text-decoration: none;
    // padding: 1rem;
    // padding: 5px 10px;
    // border: 3px solid white;
    // border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 18px;
    border-bottom: 2px solid #007aff;
  }
  // .nav-link1:hover {
  //   border: 3px solid #007aff;
  //   color: white;
  //   background: #007aff;
  //   border-radius: 5px;
  // }
  .nav-link2 {
    color: white;
    background: #007aff;
    text-decoration: none;
    // padding: 1rem;
    padding: 4px 15px;
    border: 3px solid #007aff;
    border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 19px;
    display: flex;
    align-items: center;
    // width: 75px;
    cursor: pointer;
    justify-content: space-around;
    width: 90px;
  }
  .nav-link2:hover {
    border: 3px solid #007aff;
    color: #007aff;
    background: white;
    border-radius: 5px;
    img {
      color: #007aff;
    }
  }
  .submenulist {
    position: absolute;
    top: 95px;
    right: 80px;
    list-style: none;
    li {
      background-color: #007aff;
      color: white;
      padding: 3px 20px;
      // border-radius: 5px;
      border: 1px solid white;
      transition: 0.3s ease-out;
      cursor: pointer;
    }
    li:hover {
      background-color: white;
      color: #007aff;
      border: 1px solid #007aff;
    }
  }
  .hidden3 {
    display: none;
  }
  @media screen and (max-width: 1111px) {
    padding: 15px 15px;
    height: 100px;
    padding-bottom: 0px;
    .log {
      display: flex;
      position: absolute;
      top: 15px;
      left: 35px;
    }
    // .navfeat {
    //   display: none;
    // }

    .navfeat {
      position: fixed;
      top: 115px;
      left: -100%;
      flex-direction: column;
      align-items: center;
      background-color: #242424;
      width: 100%;
      height: 100%;
      padding: 1.6rem 0;
      transition: all 300ms ease-in-out;
      z-index: 100;
    }
    .navfeat.active {
      left: 0%;
      transition: all 300ms ease-in-out;
    }
    .lists {
      color: white;
      width: 100%;
      text-align: center;
    }
    .nav-link {
      font-size: 1.8rem;
      width: 100%;
      color: white;
      border: 3px solid #242424;
    }
    .nav-link1 {
      font-size: 1.8rem;
      width: 100%;
      color: white;
      border: 3px solid #242424;
    }
    .mobile-menu-icon {
      display: block;
    }
    .nav-link2 {
      width: 120px;
    }
    .submenulist {
      right: 30%;
    }
  }
`;
