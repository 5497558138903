import React, { useState, useLayoutEffect, useEffect } from "react";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo1 from "../images/tvish logo.png";
import { Link, useHistory } from "react-router-dom";
import profileimg1 from "../images/Profile.svg";
import profileimg2 from "../images/Profileblue.svg";
import googleimg from "../images/google.png";
import Navbar from "./Navbar";
import { db } from "../firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
} from "firebase/firestore/lite";
import bac11 from "../images/Rectangle 69.svg";
import tshirt from "../images/010567_1 1.svg";
import heart1 from "../images/heart_outline.svg";
import roundblue from "../images/Group 344.svg";
import smalltshirt from "../images/tshirtsmall.svg";
import modalimage from "../images/Hands Procrastinating.svg";
import feature1 from "../images/Black_halfsleeves.svg";
import feature2 from "../images/Black_fullsleeves.svg";
import feature3 from "../images/Black_polo.svg";
import feature4 from "../images/Black_sweatshirt.svg";
import feature5 from "../images/Black_hoodie.svg";
import feature6 from "../images/Blue_halfsleeves.svg";
import feature7 from "../images/Blue_fullsleeves.svg";
import feature8 from "../images/Blue_polo.svg";
import feature9 from "../images/Blue_sweatshirt.svg";
import feature10 from "../images/Blue_hoodie.svg";
import Footer2 from "./Footer2";
import Footer from "./Footer";
import video from "../images/appfinalad.mp4";
import ReactPlayer from "react-player";

import TextField from "@mui/material/TextField";
import navheart from "../images/heart_outline1.svg";
import navbookmark from "../images/Vector1.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Ecommerce.css";
import Productimage from "./Productimage";
import { updateDoc, deleteDoc, doc } from "firebase/firestore/lite";

function Ecommerce() {
  const [isMobile, setIsMobile] = useState(false);
  const [featureimage1, setfeatureimage1] = useState(feature1);
  const [featureimage2, setfeatureimage2] = useState(feature2);
  const [featureimage3, setfeatureimage3] = useState(feature3);
  const [featureimage4, setfeatureimage4] = useState(feature4);
  const [featureimage5, setfeatureimage5] = useState(feature5);
 


  
  //login end
  const changeiconprof = (vi) => {
    if (vi == 1) {
      const list = document.querySelector(".submenulist");
      if (list) {
        list.classList.remove("hidden3");
      }
    } else {
      const list = document.querySelector(".submenulist");
      if (list) {
        list.classList.add("hidden3");
      }
    }
  };

  const clickHandler = () => {
    setIsMobile(!isMobile);
  };
  const changecolor = (ab) => {
    if (ab == 1) {
      setfeatureimage1(feature6);
    }
    if (ab == 2) {
      setfeatureimage2(feature7);
    }
    if (ab == 3) {
      setfeatureimage3(feature8);
    }
    if (ab == 4) {
      setfeatureimage4(feature9);
    }
    if (ab == 5) {
      setfeatureimage5(feature10);
    }
  };
  const changecolor1 = (ab1) => {
    if (ab1 == 1) {
      setfeatureimage1(feature1);
    }
    if (ab1 == 2) {
      setfeatureimage2(feature2);
    }
    if (ab1 == 3) {
      setfeatureimage3(feature3);
    }
    if (ab1 == 4) {
      setfeatureimage4(feature4);
    }
    if (ab1 == 5) {
      setfeatureimage5(feature5);
    }
  };
 
 
  const [products, setproducts] = useState([]);
  const collectionRef = collection(db, "ClothesData");
  useEffect(() => {
    const getproducts = async () => {
      const data = await getDocs(collectionRef);
      setproducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getproducts();
  }, []);
  const [loadmorevalue, setloadmorevalue] = useState(4);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1460,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1149,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 915,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <Container>
       <Navbar id="1" />
      <Welcome>
        <video
          className="videostyle"
          // controls="controls"
          autoPlay={true}
          src={video}
          // muted
          loop
        />
      </Welcome>
      <h1
        style={{
          textAlign: "center",
          fontSize: "60px",
          color: "#505050",
          marginTop: "60px",
        }}
      >
        Categories
      </h1>
      <Sections1>
        <div className="section1header">
          <Carousel {...settings}>
            <div>
              <div
                onMouseEnter={() => {
                  changecolor(1);
                }}
                onMouseLeave={() => {
                  changecolor1(1);
                }}
                className="featureschild"
              >
                <Link style={{ textDecoration: "none" }} to="/featurepage">
                  <img
                    style={{ width: "80px", height: "80px" }}
                    src={featureimage1}
                  />
                  TSHIRTS
                </Link>
              </div>
            </div>
            <div>
              <div
                onMouseEnter={() => {
                  changecolor(2);
                }}
                onMouseLeave={() => {
                  changecolor1(2);
                }}
                className="featureschild"
              >
                <Link style={{ textDecoration: "none" }} to="/featurepage">
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      position: "relative",
                      left: "10px",
                    }}
                    src={featureimage2}
                  />
                  FULL SLEEVES
                </Link>
              </div>
            </div>
            <div>
              <div
                onMouseEnter={() => {
                  changecolor(3);
                }}
                onMouseLeave={() => {
                  changecolor1(3);
                }}
                className="featureschild"
              >
                <Link style={{ textDecoration: "none" }} to="/featurepage">
                  <img
                    style={{ width: "80px", height: "80px" }}
                    src={featureimage3}
                  />
                  POLO
                </Link>
              </div>
            </div>
            <div>
              <div
                onMouseEnter={() => {
                  changecolor(4);
                }}
                onMouseLeave={() => {
                  changecolor1(4);
                }}
                className="featureschild"
              >
                <Link style={{ textDecoration: "none" }} to="/featurepage">
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      position: "relative",
                      left: "10px",
                    }}
                    src={featureimage4}
                  />
                  SWEATSHIRTS
                </Link>
              </div>
            </div>
            <div>
              <div
                onMouseEnter={() => {
                  changecolor(5);
                }}
                onMouseLeave={() => {
                  changecolor1(5);
                }}
                className="featureschild"
              >
                <Link style={{ textDecoration: "none" }} to="/featurepage">
                  <img
                    style={{ width: "80px", height: "80px" }}
                    src={featureimage5}
                  />
                  HOODIES
                </Link>
              </div>
            </div>
          </Carousel>
        </div>
        <div className="section1images">
          {products.slice(0, loadmorevalue).map((product) => (
            <Productimage
              pname={product.name}
              pprice={product.price}
              mainimg={product.images[0]}
              productid={product.id}
            />
          ))}
        </div>
      </Sections1>

      <br />
      <br />
      <hr className="hori1" />
      <div
        className="loadmore"
        onClick={() => setloadmorevalue(loadmorevalue + 4)}
      >
        Load more
      </div>
      <Perfectfit>
        <h1
          style={{
            color: "#007aff",
            fontWeight: "bold",
            fontSize: "40px",
            letterSpacing: "0.2em",
            marginBottom: "0",
          }}
        >
          Perfect Fit
        </h1>
        <p
          style={{
            color: "rgba(80, 80, 80, 0.55)",
            width: "300px",
            fontSize: "20px",
          }}
        >
          Dimensional model of each user to find the best fit of clothes.
        </p>
        <Link to="/featurepage">
          <div className="shopnow1">Shop now</div>
        </Link>
      </Perfectfit>

      <br />
      <br />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/tvish_ar/"
        style={{ textDecoration: "none" }}
      >
        <h1
          style={{
            fontSize: "40px",
            padding: "80px 0",
            background: "#D6E9FF",
            textAlign: "center",
            color: "white",
            fontWeight: "normal",
            margin: "0",
            cursor: "pointer",
          }}
        >
          FOLLOW US ON INSTAGRAM
        </h1>
      </a>
      <Footer />
    </Container>
  );
}

export default Ecommerce;

const Container = styled.div`
  @media screen and (max-width: 475px) {
    width: 475px;
    // display: inline-block;
  }
  .hori1 {
    width: 80%;
    margin: 0 auto;
  }
  .loadmore {
    padding: 10px 15px;
    border: 2px solid #505050;
    color: #505050;
    display: inline-block;
    position: relative;
    left: 46%;
    bottom: 26px;
    background: white;
    cursor: pointer;
    transition: 0.3s ease-out;
    border-radius: 5px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  }
  .loadmore:hover {
    .hori1 {
      color: #007aff;
    }
    border: 2px solid #007aff;
    color: #007aff;
  }
`;

const Carousel = styled(Slider)`
  .slick-prev:before,
  .slick-next:before {
    color: black;
  }
`;

const Sections2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 50px;
  flex-wrap: wrap;
  .section2child {
    width: 300px;
    height: 580px;
  }
`;

const Perfectfit = styled.div`
  padding: 30px 150px;
  background: #e6f1ff;
  border-radius: 10px;
  width: 60%;
  margin: auto;
  margin-top: 50px;
  .shopnow1 {
    padding: 10px 15px;
    border: 2px solid #007aff;
    color: #007aff;
    background: white;
    display: inline-block;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    cursor: pointer;
  }
  @media screen and (max-width: 650px) {
    padding: 30px;
  }
`;

const Navbar1 = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // padding-left: 80px;
  // padding-right: 30px;
  padding: 10px 50px;
  position: fixed;

  border-bottom: 2px solid #50505026;
  top: 0;
  width: 95%;
  z-index: 1000;
  .navfeat {
    display: flex;
  }
  .lists {
    list-style: none;
    margin: 25px;
    font-size: 20px;
    font-weight: 600;
    line-height: 34.05px;
    color: #1938a7;
  }

  .mobile-menu-icon {
    display: none;
  }
  .nav-link {
    color: rgba(80, 80, 80, 0.5);
    text-decoration: none;
    // padding: 1rem;
    // padding: 5px 10px;
    // border: 3px solid white;
    // border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 18px;
  }
  .nav-link:hover {
    // border: 3px solid #007aff;
    // color: white;
    // background: #007aff;
    // border-radius: 5px;
    color: black;
  }
  .nav-link1 {
    color: #007aff;
    text-decoration: none;
    // padding: 1rem;
    // padding: 5px 10px;
    // border: 3px solid white;
    // border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 18px;
    border-bottom: 2px solid #007aff;
  }
  // .nav-link1:hover {
  //   border: 3px solid #007aff;
  //   color: white;
  //   background: #007aff;
  //   border-radius: 5px;
  // }
  .nav-link2 {
    color: white;
    background: #007aff;
    text-decoration: none;
    // padding: 1rem;
    padding: 4px 15px;
    border: 3px solid #007aff;
    border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 19px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-around;
    width: 90px;
  }
  .nav-link2:hover {
    border: 3px solid #007aff;
    color: #007aff;
    background: white;
    border-radius: 5px;
    img {
      color: #007aff;
    }
  }
  .submenulist {
    position: absolute;
    top: 100px;
    right: 80px;
    list-style: none;
    li {
      background-color: #007aff;
      color: white;
      padding: 3px 20px;
      // border-radius: 5px;
      border: 1px solid white;
      transition: 0.3s ease-out;
      cursor: pointer;
    }
    li:hover {
      background-color: white;
      color: #007aff;
      border: 1px solid #007aff;
    }
  }
  .hidden3 {
    display: none;
  }
  @media screen and (max-width: 500px) {
    .nav-link2 {
      width: 100px;
    }
  }
  @media screen and (max-width: 1111px) {
    padding: 15px 15px;
    height: 100px;
    padding-bottom: 0px;
    .log {
      display: flex;
      position: absolute;
      top: 15px;
      left: 35px;
    }
    // .navfeat {
    //   display: none;
    // }

    .navfeat {
      position: fixed;
      top: 100px;
      left: -100%;
      flex-direction: column;
      align-items: center;
      background-color: #242424;
      width: 100%;
      height: 100%;
      padding: 1.6rem 0;
      transition: all 300ms ease-in-out;
      z-index: 100;
    }
    .navfeat.active {
      left: 0%;
      transition: all 300ms ease-in-out;
    }
    .lists {
      color: white;
      width: 100%;
      text-align: center;
    }
    .nav-link {
      font-size: 1.8rem;
      width: 100%;
      color: white;
      border: 3px solid #242424;
    }
    .nav-link1 {
      font-size: 1.8rem;
      width: 100%;
      color: white;
      border: 3px solid #242424;
    }
    .mobile-menu-icon {
      display: block;
    }
    .nav-link2 {
      // width: 110px;
    }
    .submenulist {
      right: 30%;
    }
  }
`;

const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    height: 700px;
  }
  .videostyle {
    margin: 0;
    margin-top: 150px;
    width: 700px;
    height: 500px;
  }
  @media screen and (max-width: 740px) {
    .videostyle {
      width: 450px;
      height: 400px;
    }
  }
`;

const Sections1 = styled.div`
  .section1header {
    width: 70%;
    margin: 50px auto;
    margin-left: 17%;
  }
  .featureschild {
    background-color: #f2f8ff;
    text-align: center;
    color: #505050;
    border-radius: 10px;
    padding: 30px 0;
    width: 150px;
    height: 100px;
    border: 2px solid #007aff;
    transition: 0.3s ease-out;
    // cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 695px) {
    .featureschild {
      padding: 20px 0;
      width: 120px;
    }
    .section1header {
      width: 81%;
      margin-left: 9%;
    }
  }

  .featureschild:hover {
    background-color: white;
    color: #007aff;
    border: 2px solid #007aff;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .section1images {
    display: flex;
    // align-items: center;
    justify-content: space-around;
    margin: 60px;
    flex-wrap: wrap;
  }
`;
