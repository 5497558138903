import React, { useState } from "react";
import styled from "styled-components";
// import akashguna from "../images/akashguna.png";

function Teamcard({ photo, name, position, linkedin }) {
  return (
    <Container>
      <div className="col">
        <img
          style={{
            width: "380px",
            height: "400px",
            margin: "10px",
            borderRadius: "5px",
          }}
          src={photo}
        />
        <div className="layer1">
          <h1 style={{ textAlign: "center" }}>{name}</h1>
          <p>{position}</p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={linkedin}
            style={{ textDecoration: "none" }}
          >
            <h3>in</h3>
          </a>
        </div>
      </div>
    </Container>
  );
}

export default Teamcard;

const Container = styled.div`
  .col {
    // cursor: pointer;
    flex-basis: 32%;
    // border-radius: 10px;
    // margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
  .col img {
    width: 300px;
    height: 200px;
    // border-radius: 15px;
    display: block;
  }
  .layer1 {
    background: transparent;
    height: 400px;
    width: 380px;
    position: absolute;
    top: 10px;
    left: 10px;
    transition: 0.5s;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }
  .layer1:hover {
    // background: linear-gradient(180deg, rgba(69, 69, 69, 0) 0%, #585858 100%);
    background: rgba(0, 0, 0, 0.65);
  }
  .layer1 p {
    // width: 100%;
    font-weight: 500;
    color: #fff;
    font-size: 20px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    top: 270px;
    // margin: 10px auto;
    opacity: 0;
    transition: 0.5s;
    text-align: left;
  }
  .layer1:hover p {
    bottom: 58%;
    opacity: 1;
  }
  .layer1 h1 {
    // width: 90%;
    width: 400px;
    font-weight: 600;
    color: white;
    font-size: 40px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    top: 200px;
    // margin-left: 10%;
    opacity: 0;
    transition: 0.5s;
  }
  .layer1:hover h1 {
    bottom: 70%;
    opacity: 1;
  }
  // .layer1 h3 {
  //   // width: 100%;
  //   font-weight: 300;
  //   color: #fff;
  //   font-size: 18px;
  //   bottom: 0;
  //   left: 50%;
  //   transform: translate(-50%);
  //   position: absolute;
  //   top: 310px;
  //   padding: 5px 10px;
  //   padding-bottom: 27px;
  //   border: 2px solid white;
  //   border-radius: 10px;
  //   opacity: 0;
  //   transition: 0.5s;
  //   text-align: left;
  //   cursor: pointer;
  //   transition: all 0.3s ease-out;
  // }
  .layer1 h3 {
    font-weight: 700;
    color: #95c4f7;
    font-size: 25px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    top: 310px;
    padding: 5px 10px;
    padding-bottom: 27px;
    opacity: 0;
    transition: 0.5s;
    text-align: left;
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  .layer1:hover h3 {
    bottom: 58%;
    opacity: 1;
  }
  // .layer1 h3:hover {
  //   background: rgba(80, 80, 80, 0.5);
  //   border: 2px solid rgba(255, 255, 255, 0.75);
  //   color: rgba(255, 255, 255, 0.75);
  // }
`;
