import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo1 from "../images/tvish logo.png";
import { Link, useHistory } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import avinashanupam from "../images/avinashanupam.jpeg";
import yashbhardwaj from "../images/yashbhardwaj.png";
// import yashrajsingh from "../images/yashraj singh.jfif";
import naithik from "../images/naithik.jpg";
import ramkrishna from "../images/ramkrishnamitra.jpg";
import rajat from "../images/rajat.jpg";
import akashguna from "../images/akashguna.png";
import yashrajsing from "../images/yashraj_singh.jpeg";
import nakshatra from "../images/nakshatra.png";
import Teamcard from "./Teamcard";
import profileimg1 from "../images/Profile.svg";
import profileimg2 from "../images/Profileblue.svg";
import navheart from "../images/heart_outline1.svg";
import navbookmark from "../images/Vector1.svg";
import vishnuimage from "../images/vishnuimageblack.jpg";
import soham from "../images/soham.jpg";

function Team() {
  const [isMobile, setIsMobile] = useState(false);
  const [profileimg, setprofileimg] = useState(profileimg1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeiconprof = (vi) => {
    if (profileimg == profileimg1 || profileimg == profileimg2) {
      if (vi == 1) {
        setprofileimg(profileimg2);
      } else {
        setprofileimg(profileimg1);
      }
    }
    if (vi == 1) {
      const list = document.querySelector(".submenulist");
      if (list) {
        list.classList.remove("hidden3");
      }
    } else {
      const list = document.querySelector(".submenulist");
      if (list) {
        list.classList.add("hidden3");
      }
    }
  };


  return (
    <Container>
         <Navbar id="1" />
      <div class="team1">
        <h1
          style={{
            color: "#007aff",
            fontSize: "60px",
            marginBottom: "50px",
            textAlign: "center",
          }}
        >
          Meet our team
        </h1>
        <p>
          We are extremely selective when it comes to adding people to our team.
          We’ve blended young, tech-savvy people with seasoned industry experts
          who are relentless in their efforts to help others succeed. At Tvish
          we are more focused at creating a community rather then just a normal
          workplace where employees will just be here for work basis. We believe
          in transparency.
        </p>
      </div>
      <div className="team2">
        <Teamcard
          photo={yashrajsing}
          name="Yashraj Singh"
          position="Co-founder"
          linkedin="https://www.linkedin.com/in/yashraj-s/"
        />
        <Teamcard
          photo={yashbhardwaj}
          name="Yash Bhardwaj"
          position="Co-founder"
          linkedin="https://www.linkedin.com/in/yash-bhardwaj-7756a8153/"
        />
        <Teamcard
          photo={ramkrishna}
          name="Ram Krishna Mitra"
          position="Advisor"
          linkedin="https://in.linkedin.com/in/prof-ram-krishna-mitra-0900901a5"
        />
        <Teamcard
          photo={akashguna}
          name="Akash Guna"
          position="ML Engineer"
          linkedin="https://www.linkedin.com/in/akash-guna-r-t/"
        />
        <Teamcard
          photo={rajat}
          name="Rajat Kaushik"
          position="ML Engineer"
          linkedin="https://www.linkedin.com/in/rajat-kaushik-8417581a7/"
        />
        <Teamcard
          photo={naithik}
          name="Naithik Jain"
          position="UI/UX Designer"
          linkedin="https://www.linkedin.com/in/naithik-jain/"
        />
        <Teamcard
          photo={soham}
          name="Soham Mistri"
          position="ML Engineer"
          linkedin="https://www.linkedin.com/in/soham-mistri-75a905199"
        />
        <Teamcard
          photo={nakshatra}
          name="Nakshatra Singh"
          position="MLOps Developer"
          linkedin="https://www.linkedin.com/in/nakshatrasinghh/"
        />
        <Teamcard
          photo={vishnuimage}
          name="Vishnu Divvela"
          position="Web Developer"
          linkedin="https://www.linkedin.com/in/vishnu-divvela-868b871b9/"
        />
      </div>
      <div className="team3">
        <p>Checking for job openings? Click on the button below.</p>
        <div onClick={() => alert("Coming Soon...")} className="careerbtn">
          Careers
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </Container>
  );
}

export default Team;

const Container = styled.div`
  .team1 {
    padding-top: 170px;
    p {
      text-align: center;
      width: 80%;
      color: rgba(80, 80, 80, 0.75);
      margin: 0 auto;
      font-weight: 400;
      font-size: 27px;
      line-height: 41px;
    }
  }
  .team2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 50px auto;
    width: 80%;
    // margin-right: 20px;
    // margin-left: 14%;
  }
  .team2card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    width: 240px;
    height: 280px;
    background: #ffffff;
    box-shadow: 3px 5px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin: 50px;
  }
  .team3 {
    p {
      font-weight: 400;
      font-size: 30px;
      line-height: 41px;

      text-align: center;
      margin: 10px;
      color: #505050;
    }
  }
  .careerbtn {
    padding: 10px 20px;
    cursor: pointer;
    width: 180px;
    color: white;
    background: #007aff;
    box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin: 20px auto;
    font-weight: 500;
    font-size: 25px;
    line-height: 41px;
    text-align: center;
  }
  @media screen and (max-width: 950px) {
    .team2card {
      margin: 30px;
      width: 280px;
    }
  }
  @media screen and (max-width: 500px) {
    width: 500px;
    // display: inline-block;
  }
`;
const Navbar1 = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // padding-left: 80px;
  // padding-right: 30px;
  padding: 10px 50px;
  position: fixed;

  border-bottom: 2px solid #50505026;
  top: 0;
  width: 95%;
  z-index: 1000;
  .navfeat {
    display: flex;
  }
  .lists {
    list-style: none;
    margin: 25px;
    font-size: 20px;
    font-weight: 600;
    line-height: 34.05px;
    color: #1938a7;
  }

  .mobile-menu-icon {
    display: none;
  }
  .nav-link {
    color: rgba(80, 80, 80, 0.5);
    text-decoration: none;
    // padding: 1rem;
    // padding: 5px 10px;
    // border: 3px solid white;
    // border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 18px;
  }
  .nav-link:hover {
    // border: 3px solid #007aff;
    // color: white;
    // background: #007aff;
    // border-radius: 5px;
    color: black;
  }
  .nav-link1 {
    color: #007aff;
    text-decoration: none;
    // padding: 1rem;
    // padding: 5px 10px;
    // border: 3px solid white;
    // border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 18px;
    border-bottom: 2px solid #007aff;
  }
  // .nav-link1:hover {
  //   border: 3px solid #007aff;
  //   color: white;
  //   background: #007aff;
  //   border-radius: 5px;
  // }
  .nav-link2 {
    color: white;
    background: #007aff;
    text-decoration: none;
    // padding: 1rem;
    padding: 4px 15px;
    border: 3px solid #007aff;
    border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 19px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-around;
    width: 90px;
    // width: 75px;
  }
  .nav-link2:hover {
    border: 3px solid #007aff;
    color: #007aff;
    background: white;
    border-radius: 5px;
    img {
      color: #007aff;
    }
  }
  .submenulist {
    position: absolute;
    top: 100px;
    right: 80px;
    list-style: none;
    li {
      background-color: #007aff;
      color: white;
      padding: 3px 20px;
      // border-radius: 5px;
      border: 1px solid white;
      transition: 0.3s ease-out;
      cursor: pointer;
    }
    li:hover {
      background-color: white;
      color: #007aff;
      border: 1px solid #007aff;
    }
  }
  .hidden3 {
    display: none;
  }
  @media screen and (max-width: 1111px) {
    padding: 15px 15px;
    height: 100px;
    padding-bottom: 0px;
    .log {
      display: flex;
      position: absolute;
      top: 15px;
      left: 35px;
    }
    // .navfeat {
    //   display: none;
    // }

    .navfeat {
      position: fixed;
      top: 100px;
      left: -100%;
      flex-direction: column;
      align-items: center;
      background-color: #242424;
      width: 100%;
      height: 100%;
      padding: 1.6rem 0;
      transition: all 300ms ease-in-out;
      z-index: 100;
    }
    .navfeat.active {
      left: 0%;
      transition: all 300ms ease-in-out;
    }
    .lists {
      color: white;
      width: 100%;
      text-align: center;
    }
    .nav-link {
      font-size: 1.8rem;
      width: 100%;
      color: white;
      border: 3px solid #242424;
    }
    .nav-link1 {
      font-size: 1.8rem;
      width: 100%;
      color: white;
      border: 3px solid #242424;
    }
    .mobile-menu-icon {
      display: block;
    }
    .nav-link2 {
      width: 120px;
    }
    .submenulist {
      right: 30%;
    }
  }
`;
