import React, { useState, useLayoutEffect, useEffect } from "react";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo1 from "../images/tvish logo.png";
import { Link, useHistory } from "react-router-dom";
import profileimg from "../images/Profile.svg";
import bac11 from "../images/Rectangle 69.svg";
import Productimage from "./Productimage";
import tshirt from "../images/010567_1 1.svg";
import Footer2 from "./Footer2";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import navheart from "../images/heart_outline1.svg";
import navbookmark from "../images/Vector1.svg";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import profileimg1 from "../images/Profile.svg";
import profileimg2 from "../images/Profileblue.svg";
import Filterprice from "./Filterprice";
import Footer from "./Footer";
import {
  MenuItem,
  FormControl,
  Select,
  Card,
  CardContent,
} from "@mui/material";

//auth and db firebase imports
import { db } from "../firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
} from "firebase/firestore/lite";
import {
  auth,
  signInWithEmailAndPassword1,
  signInWithGoogle1,
  logout,
} from "../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import { updateDoc, deleteDoc, doc } from "firebase/firestore/lite";

function Featurepage() {
  const [isMobile, setIsMobile] = useState(false);
  const [icon1, seticon1] = useState(<KeyboardArrowDownIcon />);
  const [icon2, seticon2] = useState(<KeyboardArrowDownIcon />);
  const [icon3, seticon3] = useState(<KeyboardArrowDownIcon />);
  const [check1, setcheck1] = useState(true);
  const [check2, setcheck2] = useState(true);
  const [check3, setcheck3] = useState(true);
  const [sortbytext, setsortbytext] = useState("Sort by");

  const clickHandler = () => {
    setIsMobile(!isMobile);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [profileimg, setprofileimg] = useState(profileimg1);
  const [islogin, setislogin] = useState(false);
  useEffect(() => {
    if (!islogin) {
      setprofileimg(profileimg1);
    }
  }, [islogin]);

  const changeiconprof = (vi) => {
    if (profileimg == profileimg1 || profileimg == profileimg2) {
      if (vi == 1) {
        setprofileimg(profileimg2);
      } else {
        setprofileimg(profileimg1);
      }
    }
    if (vi == 1) {
      const list = document.querySelector(".submenulist");
      if (list) {
        list.classList.remove("hidden3");
      }
    } else {
      const list = document.querySelector(".submenulist");
      if (list) {
        list.classList.add("hidden3");
      }
    }
  };
  const activatedropdown = (check, dropno) => {
    if (dropno == 1) {
      if (check === true) {
        document.querySelector(".dropdown1").classList.remove("hidden");
        seticon1(<KeyboardArrowUpIcon />);
        setcheck1(!check1);
      } else {
        document.querySelector(".dropdown1").classList.add("hidden");
        seticon1(<KeyboardArrowDownIcon />);
        setcheck1(!check1);
      }
    }
    if (dropno == 2) {
      if (check === true) {
        document.querySelector(".dropdown2").classList.remove("hidden");
        seticon2(<KeyboardArrowUpIcon />);
        setcheck2(!check2);
      } else {
        document.querySelector(".dropdown2").classList.add("hidden");
        seticon2(<KeyboardArrowDownIcon />);
        setcheck2(!check2);
      }
    }
    if (dropno == 3) {
      if (check === true) {
        document.querySelector(".dropdown3").classList.remove("hidden");
        seticon3(<KeyboardArrowUpIcon />);
        setcheck3(!check3);
      } else {
        document.querySelector(".dropdown3").classList.add("hidden");
        seticon3(<KeyboardArrowDownIcon />);
        setcheck3(!check3);
      }
    }
  };
  const changefilter = async (e) => {
    var output = e.target.value;
    var output2 = "Sort by : " + output;
    setsortbytext(output);
    console.log("filter changed");
  };

  const openmobfilbtn = () => {
    document.querySelector(Filters).style.right = "-3%";
    document.querySelector(Filters).style.transition = "all 300ms ease-in-out";
    document.querySelector(Filters).style.width = "100%";
  };

  //products database

  const [products, setproducts] = useState([]);
  const collectionRef = collection(db, "ClothesData");
  useEffect(() => {
    const getproducts = async () => {
      const data = await getDocs(collectionRef);
      setproducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getproducts();
  }, []);

  //products database ends

  //loginorprofile starts
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      setislogin(false);
      return;
    }
    if (user) {
      setislogin(true);
    }
    if (error) {
      setislogin(false);
    }
  }, [user, loading]);

  const [name, setName] = useState("");
  const [firstnametoshow, setfirstnametoshow] = useState("");
  const fetchUserName = async () => {
    try {
      const userscollectionref22 = collection(db, "usersdatafromwebsite");
      // const query = await db
      //   .collection("usersdatafromwebsite")
      //   .where("uid", "==", user?.uid)
      //   .get();
      const query1 = query(userscollectionref22, where("uid", "==", user?.uid));
      // const data = await query1.docs[0].data();
      const dataSnapshot = await getDocs(query1);
      setName(dataSnapshot.docs[0].data().name);
      var firstnametoshow1 = dataSnapshot.docs[0].data().name;
      firstnametoshow1 = firstnametoshow1.split(" ");
      firstnametoshow1 = firstnametoshow1[0];
      setfirstnametoshow(firstnametoshow1);
      if (dataSnapshot.docs[0].data().authProvider === "google") {
        setprofileimg(dataSnapshot.docs[0].data().photo);
      }
      setislogin(true);
      // const data = await getDocs(collectionRef);
      // setproducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  };
  useEffect(() => {
    if (loading) return;
    if (!user) {
      setislogin(false);
    }
    if (user) {
      console.log(user);
      fetchUserName();
    }
  }, [user, loading]);
  useEffect(() => {
    if (name === "") {
      fetchUserName();
    }
  }, [name]);

  //loginorprofile ends

  return (
    <Container>
      <Navbar1>
        <Link to="/">
          <img
            style={{ width: "90px", height: "50px", marginLeft: "2%" }}
            src={logo1}
          />
        </Link>
        <div style={{ display: "flex" }}>
          <ul className={isMobile ? "navfeat active" : "navfeat"}>
            {/* <li className="lists">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li> */}
            <li className="lists">
              <Link to="/aboutus" className="nav-link">
                Home
              </Link>
            </li>
            <li className="lists">
              <a href="/" className="nav-link1">
                Shop
              </a>
            </li>
            <li className="lists">
              <Link to="/team" className="nav-link">
                Team
              </Link>
            </li>
            <li className="lists">
              <Link to="#" className="nav-link">
                Contact
              </Link>
            </li>
            <li className="lists">
              <Link to="#" className="nav-link">
               Join Waitlist
              </Link>
            </li>
          </ul>
        </div>
        {!islogin && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              onClick={() =>
                alert(
                  "We are Sorry! you need to first have an account for using Wishlist."
                )
              }
              src={navheart}
            />
            {/* <img src={navbookmark} /> */}
            <ul style={{ paddingLeft: "0" }}>
              <li className="lists">
                <Link
                  onMouseEnter={() => {
                    changeiconprof(1);
                  }}
                  onMouseLeave={() => {
                    changeiconprof(2);
                  }}
                  to="/"
                  className="nav-link2"
                >
                  <img src={profileimg} />
                  Login
                </Link>
              </li>
            </ul>
          </div>
        )}
        {islogin && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link to="/wishlistpage">
              <img src={navheart} />
            </Link>
            {/* <img onClick={logout} src={navbookmark} /> */}
            <ul style={{ paddingLeft: "0" }}>
              <li className="lists">
                <a
                  onMouseEnter={() => {
                    changeiconprof(1);
                  }}
                  onMouseLeave={() => {
                    changeiconprof(2);
                  }}
                  className="nav-link2"
                  // onClick={() => alert(name)}
                  // onClick={logout}
                >
                  <img
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "20px",
                      marginRight: "5px",
                    }}
                    src={profileimg}
                  />
                  {firstnametoshow}
                  <ul className="submenulist hidden3">
                    <li>Settings</li>
                    <li
                      onClick={() => {
                        logout();
                        setislogin(false);
                      }}
                    >
                      Logout
                    </li>
                  </ul>
                </a>
              </li>
            </ul>
          </div>
        )}
        <div className="mobile-menu-icon" onClick={clickHandler}>
          {isMobile ? (
            <CloseIcon
              style={{
                cursor: "pointer",
                width: "40px",
                height: "40px",
                border: "1px solid white",
                borderRadius: "10px",
              }}
            />
          ) : (
            <MenuIcon
              style={{
                cursor: "pointer",
                width: "40px",
                height: "40px",
                border: "1px solid white",
                borderRadius: "10px",
              }}
            />
          )}
        </div>
      </Navbar1>
      {/* <Welcome>
        <img style={{ width: "90%" }} src={bac11} />
      </Welcome> */}
      <Main>
        {/* <Filters>
          <div className="insidefiltsidebar">
            <h1
              style={{
                color: "#007AFF",
                fontWeight: "600",
                fontSize: "32px",
                marginLeft: "40px",
              }}
            >
              Categories
            </h1>
            <p
              onClick={() => {
                activatedropdown(check1, 1);
              }}
              style={{
                cursor: "pointer",
                display: "flex",
                width: "200px",
                marginLeft: "40px",
                fontSize: "16px",
              }}
            >
              T-shirt {icon1}
            </p>
            <div className="dropdown1 hidden">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <p style={{ color: "#505050", margin: "0" }}>Polo</p>
                <p style={{ color: "#505050", margin: "0" }}>5487</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <p style={{ color: "#505050", margin: "0" }}>Full sleeves</p>
                <p style={{ color: "#505050", margin: "0" }}>745</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <p style={{ color: "#505050", margin: "0" }}>Half sleeves</p>
                <p style={{ color: "#505050", margin: "0" }}>7254</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <p style={{ color: "#505050", margin: "0" }}>Sweat shirt</p>
                <p style={{ color: "#505050", margin: "0" }}>2243</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <p style={{ color: "#505050", margin: "0" }}>Hoodie</p>
                <p style={{ color: "#505050", margin: "0" }}>5354</p>
              </div>
            </div>
            <p
              style={{
                cursor: "pointer",
                display: "flex",
                width: "200px",
                marginLeft: "40px",
                fontSize: "16px",
              }}
            >
              Shoes <KeyboardArrowDownIcon />
            </p>
            <p
              style={{
                cursor: "pointer",
                display: "flex",
                width: "200px",
                marginLeft: "40px",
                fontSize: "16px",
              }}
            >
              Furniture <KeyboardArrowDownIcon />
            </p>
            <hr style={{ width: "60%", margin: "25px auto" }} />
            <h1
              style={{
                color: "#007AFF",
                fontWeight: "600",
                fontSize: "30px",
                marginLeft: "40px",
              }}
            >
              Filter by
            </h1>
            <p
              style={{
                cursor: "pointer",
                display: "flex",
                width: "200px",
                marginLeft: "40px",
                fontSize: "16px",
              }}
            >
              Price
            </p>
            <Filterprice />
            <p
              onClick={() => activatedropdown(check2, 2)}
              style={{
                cursor: "pointer",
                display: "flex",
                width: "200px",
                marginLeft: "40px",
                fontSize: "16px",
              }}
            >
              Brands {icon2}
            </p>
            <div className="dropdown2 hidden">
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <input type="checkbox" class="item22" checked={true} />
                <p style={{ margin: "0" }}>GAP</p>
                <p style={{ margin: "0" }}>5487</p>
              </div>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <input type="checkbox" class="item22" />
                <p style={{ margin: "0" }}>Zara</p>
                <p style={{ margin: "0", position: "absolute", right: "80%" }}>
                  745
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <input type="checkbox" class="item22" />
                <p style={{ margin: "0" }}>H&M</p>
                <p style={{ margin: "0", position: "absolute", right: "80%" }}>
                  7254
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <input type="checkbox" class="item22" />
                <p style={{ margin: "0" }}>Gucci</p>
                <p style={{ margin: "0", position: "absolute", right: "80%" }}>
                  2243
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <input type="checkbox" class="item22" />
                <p style={{ margin: "0" }}>Dolce & Gabbana</p>
                <p style={{ margin: "0", position: "absolute", right: "80%" }}>
                  5354
                </p>
              </div>
              <p style={{ color: "#007AFF", margin: "0", marginLeft: "20px" }}>
                + 272 more
              </p>
            </div>
            <p
              onClick={() => activatedropdown(check3, 3)}
              style={{
                cursor: "pointer",
                display: "flex",
                width: "200px",
                marginLeft: "40px",
                fontSize: "16px",
              }}
            >
              Material {icon3}
            </p>
            <div className="dropdown3 hidden">
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <input type="checkbox" class="item22" checked={true} />
                <p style={{ margin: "0" }}>Cotton</p>
                <p style={{ margin: "0", position: "absolute", right: "80%" }}>
                  5487
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <input type="checkbox" class="item22" />
                <p style={{ margin: "0" }}>Cotten Linen</p>
                <p style={{ margin: "0", position: "absolute", right: "80%" }}>
                  745
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <input type="checkbox" class="item22" />
                <p style={{ margin: "0" }}>Linen</p>
                <p style={{ margin: "0", position: "absolute", right: "80%" }}>
                  7254
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <input type="checkbox" class="item22" />
                <p style={{ margin: "0" }}>Polyster</p>
                <p style={{ margin: "0", position: "absolute", right: "80%" }}>
                  2243
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  width: "200px",
                }}
              >
                <input type="checkbox" class="item22" />
                <p style={{ margin: "0" }}>Polycotten</p>
                <p style={{ margin: "0", position: "absolute", right: "80%" }}>
                  5354
                </p>
              </div>
              <p style={{ color: "#007AFF", margin: "0", marginLeft: "20px" }}>
                + 272 more
              </p>
            </div>
            <p
              style={{
                cursor: "pointer",
                display: "flex",
                width: "200px",
                marginLeft: "40px",
                fontSize: "16px",
              }}
            >
              Size <KeyboardArrowDownIcon />
            </p>
            <p
              style={{
                cursor: "pointer",
                display: "flex",
                width: "200px",
                marginLeft: "40px",
                fontSize: "16px",
              }}
            >
              Ratings <KeyboardArrowDownIcon />
            </p>
            <p
              style={{
                cursor: "pointer",
                display: "flex",
                width: "200px",
                marginLeft: "40px",
                fontSize: "16px",
              }}
            >
              Colours <KeyboardArrowDownIcon />
            </p>
          </div>
        </Filters> */}
        <Productside>
          {/* <div className="sortfiltershowingdiv">
            <FormControl className="app__dropdown">
              <Select
                variant="outlined"
                value={sortbytext}
                onChange={changefilter}
              >
                <MenuItem value="Sort by">Sort by</MenuItem>
                {[
                  "Sort by : Popularity",
                  "Sort by : A-Z",
                  "Sort by : Newest First",
                  "Sort by : Price Low to High",
                  "Sort by : Price High to Low",
                ].map((sorteditem) => (
                  <MenuItem value={sorteditem}>{sorteditem}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <div onClick={() => openmobfilbtn()} className="mobfilbtn">
              Filters
            </div>
            <h1
              style={{
                fontWeight: "600",
                fontSize: "24px",
                color: "rgba(80, 80, 80, 0.5)",
                textAlign: "center",
              }}
            >
              Showing 1-12 of 28 results
            </h1>
          </div> */}
          <br />
          <br />
          <br />
          <br />
          <br />

          <div className="productimagesdiv">
            {products.map((product) => (
              <Productimage
                pname={product.name}
                pprice={product.price}
                mainimg={product.images[0]}
                productid={product.id}
              />
            ))}
          </div>
          {/* <p style={{ position: "absolute", right: "3%" }}>
            Page 01 - 02 - 03 ->
          </p> */}
        </Productside>
      </Main>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/tvish_ar/"
        style={{ textDecoration: "none" }}
      >
        <h1
          style={{
            fontSize: "40px",
            padding: "80px 0",
            background: "#D6E9FF",
            textAlign: "center",
            color: "white",
            fontWeight: "normal",
            margin: "0",
            cursor: "pointer",
          }}
        >
          FOLLOW US ON INSTAGRAM
        </h1>
      </a>
      <Footer />
    </Container>
  );
}

export default Featurepage;

const Container = styled.div``;

const Main = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
`;
const Filters = styled.div`
  width: 27%;
  background: #f2f8ff;
  border-radius: 10px;
  .dropdown1 {
    margin-left: 100px;
  }
  .dropdown2 {
    margin-left: 70px;
    p {
      color: rgba(80, 80, 80, 0.5);
    }
  }
  .dropdown3 {
    margin-left: 70px;
    p {
      color: rgba(80, 80, 80, 0.5);
    }
  }
  .hidden {
    display: none;
  }
  input:checked + p + p {
    // text-decoration: line-through;
    // text-decoration-color: black;
    color: black;
  }
  input:checked + p {
    // text-decoration: line-through;
    // text-decoration-color: black;
    color: black;
  }
  input.item22 {
    margin-top: 6px;
  }
  @media screen and (max-width: 1154px) {
    width: 40%;
    margin-right: 10px;
  }
  @media screen and (max-width: 600px) {
    position: fixed;
    top: 110px;
    right: -110%;
    // flex-direction: column;
    // align-items: center;
    width: 100%;
    height: 100%;
    // padding: 1.6rem 0;
    transition: all 300ms ease-in-out;
    z-index: 1000000;
    .insidefiltsidebar {
      overflow-y: scroll;
      height: 80vh;
    }
  }
  .filtersidebar.active {
    right: 0%;
    transition: all 300ms ease-in-out;
  }
`;
const Productside = styled.div`
  // width: 65%;
  // margin-right: 2%;
  margin-bottom: 70px;
  .productimagesdiv {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .sortfiltershowingdiv {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .mobfilbtn {
    border: 2px solid #007aff;
    border-radius: 10px;
    color: #007aff;
    background-color: #f2f8ff;
    padding: 12px 15px;
    text-align: center;
    height: 25px;
    cursor: pointer;
    display: none;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    .productimagesdiv {
      justify-content: space-around;
    }
    .sortfiltershowingdiv {
      justify-content: space-around;
    }
  }
  @media screen and (max-width: 600px) {
    .mobfilbtn {
      display: block;
    }
  }
`;

const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    height: 700px;
  }
`;

const Navbar1 = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // padding-left: 80px;
  // padding-right: 30px;
  padding: 10px 50px;
  position: fixed;

  border-bottom: 2px solid #50505026;
  top: 0;
  width: 95%;
  z-index: 1000;
  .navfeat {
    display: flex;
  }
  .lists {
    list-style: none;
    margin: 25px;
    font-size: 20px;
    font-weight: 600;
    line-height: 34.05px;
    color: #1938a7;
  }

  .mobile-menu-icon {
    display: none;
  }
  .nav-link {
    color: rgba(80, 80, 80, 0.5);
    text-decoration: none;
    // padding: 1rem;
    // padding: 5px 10px;
    // border: 3px solid white;
    // border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 18px;
  }
  .nav-link:hover {
    // border: 3px solid #007aff;
    // color: white;
    // background: #007aff;
    // border-radius: 5px;
    color: black;
  }
  .nav-link1 {
    color: #007aff;
    text-decoration: none;
    // padding: 1rem;
    // padding: 5px 10px;
    // border: 3px solid white;
    // border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 18px;
    border-bottom: 2px solid #007aff;
  }
  // .nav-link1:hover {
  //   border: 3px solid #007aff;
  //   color: white;
  //   background: #007aff;
  //   border-radius: 5px;
  // }
  .nav-link2 {
    color: white;
    background: #007aff;
    text-decoration: none;
    // padding: 1rem;
    padding: 4px 15px;
    border: 3px solid #007aff;
    border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 19px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-around;
    width: 90px;
    // width: 75px;
  }
  .nav-link2:hover {
    border: 3px solid #007aff;
    color: #007aff;
    background: white;
    border-radius: 5px;
    img {
      color: #007aff;
    }
  }
  .submenulist {
    position: absolute;
    top: 100px;
    right: 80px;
    list-style: none;
    li {
      background-color: #007aff;
      color: white;
      padding: 3px 20px;
      // border-radius: 5px;
      border: 1px solid white;
      transition: 0.3s ease-out;
      cursor: pointer;
    }
    li:hover {
      background-color: white;
      color: #007aff;
      border: 1px solid #007aff;
    }
  }
  .hidden3 {
    display: none;
  }
  @media screen and (max-width: 1111px) {
    padding: 15px 15px;
    height: 100px;
    padding-bottom: 0px;
    .log {
      display: flex;
      position: absolute;
      top: 15px;
      left: 35px;
    }
    // .navfeat {
    //   display: none;
    // }

    .navfeat {
      position: fixed;
      top: 100px;
      left: -100%;
      flex-direction: column;
      align-items: center;
      background-color: #242424;
      width: 100%;
      height: 100%;
      padding: 1.6rem 0;
      transition: all 300ms ease-in-out;
      z-index: 100;
    }
    .navfeat.active {
      left: 0%;
      transition: all 300ms ease-in-out;
    }
    .lists {
      color: white;
      width: 100%;
      text-align: center;
    }
    .nav-link {
      font-size: 1.8rem;
      width: 100%;
      color: white;
      border: 3px solid #242424;
    }
    .nav-link1 {
      font-size: 1.8rem;
      width: 100%;
      color: white;
      border: 3px solid #242424;
    }
    .mobile-menu-icon {
      display: block;
    }
    .nav-link2 {
      width: 120px;
    }
    .submenulist {
      right: 30%;
    }
  }
`;
