import React, { useState } from "react";
import styled from "styled-components";

function Feedback() {
  return (
    <Feedback1>
      <div className="feedbackdiv1">
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <h1 style={{ fontSize: "150px", margin: "0", padding: "0" }}>“</h1>
          <div className="circle"></div>
        </div>
        <p style={{ margin: "40px" }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi morbi
          elementum in arcu cras. A, nunc lacus, egestas sit in amet. Sed nam in
          tortor aliquet pellentesque tristique eu faucibus ullamcorper.{" "}
        </p>
        <h1 style={{ marginLeft: "40px" }}>-Naithik Jain</h1>
      </div>
      <div>
        <div className="feedbackdiv2">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                fontSize: "90px",
                margin: "0",
                padding: "0",
                height: "90px",
              }}
            >
              “
            </h1>
            <div className="circle1"></div>
          </div>
          <p style={{ margin: "10px 40px" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi morbi
            elementum in arcu cras.
          </p>
          <h1 style={{ marginLeft: "40px" }}>-Naithik Jain</h1>
        </div>
        <div className="feedbackdiv3">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                fontSize: "90px",
                margin: "0",
                padding: "0",
                height: "90px",
              }}
            >
              “
            </h1>
            <div className="circle1"></div>
          </div>
          <p style={{ margin: "10px 40px" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi morbi
            elementum in arcu cras.
          </p>
          <h1 style={{ marginLeft: "40px" }}>-Naithik Jain</h1>
        </div>
      </div>
      <div>
        <div className="feedbackdiv4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                fontSize: "100px",
                margin: "0",
                padding: "0",
                height: "110px",
              }}
            >
              “
            </h1>
            <div className="circle"></div>
          </div>

          <p style={{ margin: "10px 40px" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi morbi
            elementum in arcu cras. A, nunc lacus, egestas sit in amet. Sed nam
            in tortor aliquet pellentesque tristique eu faucibus ullamcorper.{" "}
          </p>
          <h1 style={{ marginLeft: "40px" }}>-Naithik Jain</h1>
        </div>
        <div className="feedbackdiv5"></div>
      </div>
    </Feedback1>
  );
}

export default Feedback;

const Feedback1 = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .feedbackdiv1 {
    background: linear-gradient(90deg, #ff5b5b -9.67%, #004171 105.67%);
    box-shadow: 1px 5px 24px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    color: white;
    width: 400px;
    margin: 20px;
  }
  .feedbackdiv2 {
    background: linear-gradient(90deg, #ff5b5b -9.67%, #004171 105.67%);
    box-shadow: 1px 5px 24px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    color: white;
    width: 400px;
    height: 250px;
    margin: 20px;
  }
  .feedbackdiv3 {
    background: white;
    box-shadow: 1px 5px 24px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    color: black;
    width: 400px;
    height: 250px;
    margin: 20px;
  }
  .feedbackdiv4 {
    background: radial-gradient(
      86.49% 264.86% at 3.86% 3.51%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
    box-shadow: 1px 5px 24px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    color: black;
    width: 400px;
    height: 310px;
    margin: 20px;
  }
  .feedbackdiv5 {
    background: radial-gradient(
      86.49% 264.86% at 3.86% 3.51%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
    box-shadow: 1px 5px 24px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    color: white;
    width: 400px;
    height: 190px;
    margin: 20px;
  }
  .circle {
    width: 100px;
    height: 100px;
    background-color: #c4c4c4;
    border-radius: 50%;
  }
  .circle1 {
    width: 80px;
    height: 80px;
    background-color: #c4c4c4;
    border-radius: 50%;
  }
`;
