import React, { useState, useEffect } from "react";
import styled from "styled-components";

import heart1 from "../images/heart_outline.svg";
import heart2 from "../images/coolicon-1.svg";
import heart3 from "../images/heart_fill_red.svg";
import roundblue from "../images/Group 344.svg";
import { Link, useHistory } from "react-router-dom";

//auth and db firebase imports
import { db } from "../firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore/lite";
import {
  auth,
  signInWithEmailAndPassword1,
  signInWithGoogle1,
  logout,
} from "../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo1 from "../images/tvish logo.png";
import profileimg1 from "../images/Profile.svg";
import profileimg2 from "../images/Profileblue.svg";
import navheart from "../images/heart_outline1.svg";
import navbookmark from "../images/Vector1.svg";

import Productimage from "./Productimage";

function Wishlistpage() {
  const [isMobile, setIsMobile] = useState(false);
  const [profileimg, setprofileimg] = useState(profileimg1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const changeiconprof = (vi) => {
    if (profileimg == profileimg1 || profileimg == profileimg2) {
      if (vi == 1) {
        setprofileimg(profileimg2);
      } else {
        setprofileimg(profileimg1);
      }
    }
    if (vi == 1) {
      const list = document.querySelector(".submenulist");
      if (list) {
        list.classList.remove("hidden3");
      }
    } else {
      const list = document.querySelector(".submenulist");
      if (list) {
        list.classList.add("hidden3");
      }
    }
  };

  const clickHandler = () => {
    setIsMobile(!isMobile);
  };

  //loginorprofile starts
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);

  const [islogin, setislogin] = useState(false);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      setislogin(false);
      return;
    }
    if (user) {
      setislogin(true);
    }
    if (error) {
      setislogin(false);
    }
  }, [user, loading]);

  const [name, setName] = useState("");
  const [userid, setuserid] = useState("");
  const [firstnametoshow, setfirstnametoshow] = useState("");
  const fetchUserName = async () => {
    try {
      const userscollectionref22 = collection(db, "usersdatafromwebsite");
      // const query = await db
      //   .collection("usersdatafromwebsite")
      //   .where("uid", "==", user?.uid)
      //   .get();
      const query1 = query(userscollectionref22, where("uid", "==", user?.uid));
      // const data = await query1.docs[0].data();
      const dataSnapshot = await getDocs(query1);
      setName(dataSnapshot.docs[0].data().name);
      var firstnametoshow1 = dataSnapshot.docs[0].data().name;
      firstnametoshow1 = firstnametoshow1.split(" ");
      firstnametoshow1 = firstnametoshow1[0];
      setfirstnametoshow(firstnametoshow1);
      setuserid(dataSnapshot.docs[0].id);

      if (dataSnapshot.docs[0].data().authProvider === "google") {
        setprofileimg(dataSnapshot.docs[0].data().photo);
      }
      setislogin(true);
      // const data = await getDocs(collectionRef);
      // setproducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  };
  useEffect(() => {
    if (loading) {
      setislogin(false);
    }
    if (!user) {
      setislogin(false);
    }
    if (user) {
      console.log(user);
      setislogin(true);
      fetchUserName();
    }
  }, [user, loading]);
  useEffect(() => {
    if (name === "") {
      fetchUserName();
    }
  }, [name]);
  //products database
  const [products, setproducts] = useState([]);

  //wishlist database
  const [wishlist, setwishlist] = useState([]);
  const fetchuserwishlist = async () => {
    try {
      const userscollectionref = collection(db, "usersdatafromwebsite");
      const query1 = query(userscollectionref, where("uid", "==", user?.uid));

      const dataSnapshot = await getDocs(query1);
      setwishlist(dataSnapshot.docs[0].data().wishlist);
      const getproducts = async () => {
        const collectionRef = collection(db, "ClothesData");
        const data = await getDocs(collectionRef);
        setproducts(
          data.docs.map((doc) => {
            if (wishlist.includes(doc.id)) {
              return { ...doc.data(), id: doc.id };
            } else {
              return;
            }
          })
        );
      };
      getproducts();
      setislogin(true);
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    fetchuserwishlist();
    if (!user) {
      setislogin(false);
      history.push("/");
    }
  }, [wishlist, user]);

  const remove = (productid) => {
    var newnotes = wishlist;
    newnotes = newnotes.filter((noteItem) => {
      return noteItem !== productid;
    });
    setwishlist(newnotes);
    const updatewishlistinfirebase = async () => {
      const usercollectionref = doc(db, "usersdatafromwebsite", userid);
      await updateDoc(usercollectionref, { wishlist: newnotes });
    };
    updatewishlistinfirebase();
  };

  return (
    <Container>
      <Navbar1>
        <Link to="/">
          <img
            style={{ width: "90px", height: "50px", marginLeft: "2%" }}
            src={logo1}
          />
        </Link>
        <div style={{ display: "flex" }}>
          <ul className={isMobile ? "navfeat active" : "navfeat"}>
            {/* <li className="lists">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li> */}
            <li className="lists">
              <Link to="/aboutus" className="nav-link">
                Home
              </Link>
            </li>
            <li className="lists">
              <Link to="/" className="nav-link1">
                Shop
              </Link>
            </li>
            <li className="lists">
              <Link to="/team" className="nav-link">
                Team
              </Link>
            </li>
            <li className="lists">
              <Link to="#" className="nav-link">
                Contact
              </Link>
            </li>
          </ul>
        </div>
        {!islogin && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={navheart} />
            {/* <img src={navbookmark} /> */}
            <ul style={{ paddingLeft: "0" }}>
              <li className="lists">
                <Link
                  onMouseEnter={() => {
                    changeiconprof(1);
                  }}
                  onMouseLeave={() => {
                    changeiconprof(2);
                  }}
                  to="/"
                  className="nav-link2"
                >
                  <img src={profileimg} />
                  Login
                </Link>
              </li>
            </ul>
          </div>
        )}
        {islogin && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={navheart} />
            {/* <img onClick={logout} src={navbookmark} /> */}
            <ul style={{ paddingLeft: "0" }}>
              <li className="lists">
                <a
                  onMouseEnter={() => {
                    changeiconprof(1);
                  }}
                  onMouseLeave={() => {
                    changeiconprof(2);
                  }}
                  className="nav-link2"
                  // onClick={() => alert(name)}
                  // onClick={logout}
                >
                  <img
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "20px",
                      marginRight: "5px",
                    }}
                    src={profileimg}
                  />
                  {firstnametoshow}
                  <ul className="submenulist hidden3">
                    <li>Settings</li>
                    <li
                      onClick={() => {
                        logout();
                        setislogin(false);
                      }}
                    >
                      Logout
                    </li>
                  </ul>
                </a>
              </li>
            </ul>
          </div>
        )}
        <div className="mobile-menu-icon" onClick={clickHandler}>
          {isMobile ? (
            <CloseIcon
              style={{
                cursor: "pointer",
                width: "40px",
                height: "40px",
                border: "1px solid white",
                borderRadius: "10px",
              }}
            />
          ) : (
            <MenuIcon
              style={{
                cursor: "pointer",
                width: "40px",
                height: "40px",
                border: "1px solid white",
                borderRadius: "10px",
              }}
            />
          )}
        </div>
      </Navbar1>
      <div
        style={{
          color: "#007aff",
          textAlign: "center",
          fontSize: "40px",
          fontWeight: "500",
          marginTop: "170px",
        }}
      >
        My Wishlist
      </div>
      {islogin && products && (
        <div className="section1images">
          {products.map((product) => {
            if (product !== undefined)
              return (
                <Productimage
                  pname={product.name}
                  pprice={product.price}
                  mainimg={product.images[0]}
                  productid={product.id}
                />
              );
          })}
        </div>
      )}
    </Container>
  );
}

export default Wishlistpage;

const Container = styled.div`
  .section1images {
    display: flex;
    // align-items: center;
    justify-content: space-around;
    margin: 60px;
    flex-wrap: wrap;
  }
`;

const Navbar1 = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // padding-left: 80px;
  // padding-right: 30px;
  padding: 10px 50px;
  position: fixed;

  border-bottom: 2px solid #50505026;
  top: 0;
  width: 95%;
  z-index: 1000;
  .navfeat {
    display: flex;
  }
  .lists {
    list-style: none;
    margin: 25px;
    font-size: 20px;
    font-weight: 600;
    line-height: 34.05px;
    color: #1938a7;
  }

  .mobile-menu-icon {
    display: none;
  }
  .nav-link {
    color: rgba(80, 80, 80, 0.5);
    text-decoration: none;
    // padding: 1rem;
    // padding: 5px 10px;
    // border: 3px solid white;
    // border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 18px;
  }
  .nav-link:hover {
    // border: 3px solid #007aff;
    // color: white;
    // background: #007aff;
    // border-radius: 5px;
    color: black;
  }
  .nav-link1 {
    color: #007aff;
    text-decoration: none;
    // padding: 1rem;
    // padding: 5px 10px;
    // border: 3px solid white;
    // border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 18px;
    border-bottom: 2px solid #007aff;
  }
  // .nav-link1:hover {
  //   border: 3px solid #007aff;
  //   color: white;
  //   background: #007aff;
  //   border-radius: 5px;
  // }
  .nav-link2 {
    color: white;
    background: #007aff;
    text-decoration: none;
    // padding: 1rem;
    padding: 4px 15px;
    border: 3px solid #007aff;
    border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 19px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-around;
    width: 90px;
  }
  .nav-link2:hover {
    border: 3px solid #007aff;
    color: #007aff;
    background: white;
    border-radius: 5px;
    img {
      color: #007aff;
    }
  }
  .submenulist {
    position: absolute;
    top: 100px;
    right: 80px;
    list-style: none;
    li {
      background-color: #007aff;
      color: white;
      padding: 3px 20px;
      // border-radius: 5px;
      border: 1px solid white;
      transition: 0.3s ease-out;
      cursor: pointer;
    }
    li:hover {
      background-color: white;
      color: #007aff;
      border: 1px solid #007aff;
    }
  }
  .hidden3 {
    display: none;
  }
  @media screen and (max-width: 500px) {
    .nav-link2 {
      width: 100px;
    }
  }
  @media screen and (max-width: 1111px) {
    padding: 15px 15px;
    height: 100px;
    padding-bottom: 0px;
    .log {
      display: flex;
      position: absolute;
      top: 15px;
      left: 35px;
    }
    // .navfeat {
    //   display: none;
    // }

    .navfeat {
      position: fixed;
      top: 100px;
      left: -100%;
      flex-direction: column;
      align-items: center;
      background-color: #242424;
      width: 100%;
      height: 100%;
      padding: 1.6rem 0;
      transition: all 300ms ease-in-out;
      z-index: 100;
    }
    .navfeat.active {
      left: 0%;
      transition: all 300ms ease-in-out;
    }
    .lists {
      color: white;
      width: 100%;
      text-align: center;
    }
    .nav-link {
      font-size: 1.8rem;
      width: 100%;
      color: white;
      border: 3px solid #242424;
    }
    .nav-link1 {
      font-size: 1.8rem;
      width: 100%;
      color: white;
      border: 3px solid #242424;
    }
    .mobile-menu-icon {
      display: block;
    }
    .nav-link2 {
      // width: 110px;
    }
    .submenulist {
      right: 30%;
    }
  }
`;
