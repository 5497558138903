import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  query,
  where,
  doc,
  setDoc,
} from "firebase/firestore/lite";
import {
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
// import firebase from "firebase";
const firebaseConfig = {
  apiKey: "AIzaSyCDW4TgV5vGUp36kHi7xtPJm9m_Qmtnisg",
  authDomain: "tvish-ecommerce.firebaseapp.com",
  databaseURL: "https://tvish-ecommerce.firebaseio.com",
  projectId: "tvish-ecommerce",
  storageBucket: "tvish-ecommerce.appspot.com",
  messagingSenderId: "415755214658",
  appId: "1:415755214658:web:3e858aab2b421cd3adb2db",
  measurementId: "G-CMHSCDBWHJ",
};
// const firebaseConfig = {
//   apiKey: "AIzaSyAAynB7ThZNMJomigJ8yhtXRTc1VLImyKY",
//   authDomain: "fir-tutorial-e0802.firebaseapp.com",
//   projectId: "fir-tutorial-e0802",
//   storageBucket: "fir-tutorial-e0802.appspot.com",
//   messagingSenderId: "230701092804",
//   appId: "1:230701092804:web:80397dcfce39b293bd9e91",
//   measurementId: "G-KL4FN46RMF",
// };
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle1 = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const userscollectionref11 = collection(db, "usersdatafromwebsite");
    const query1 = query(userscollectionref11, where("uid", "==", user.uid));
    // const query = await db
    //   .collection("users")
    //   .where("uid", "==", user.uid)
    //   .get();
    const dataSnapshot = await getDocs(query1);
    if (dataSnapshot.docs.length === 0) {
      // await db.collection("users").add({
      //   uid: user.uid,
      //   name: user.displayName,
      //   authProvider: "google",
      //   email: user.email,
      // });
      await addDoc(userscollectionref11, {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        photo: user.photoURL,
        wishlist: [],
      });
    }
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

const signInWithEmailAndPassword1 = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword1 = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const userscollectionref = collection(db, "usersdatafromwebsite");
    await addDoc(userscollectionref, {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
      wishlist: [],
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordResetEmail1 = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = async () => {
  await signOut(auth);
};

export {
  auth,
  db,
  signInWithGoogle1,
  signInWithEmailAndPassword1,
  registerWithEmailAndPassword1,
  sendPasswordResetEmail1,
  logout,
};
