import React, { useState,useEffect } from "react";
import styled from "styled-components";
import video from "../images/appfinalad.mp4";
import mainbac from "../images/BG_landingpage.svg";
import { Link } from "react-router-dom";
import playstoreicon from "../images/google-play.png";
import appstoreicon from "../images/app-store.png";
import { borderRadius } from "@mui/system";
import Waitlist from "./Waitlist";
import { useHistory } from "react-router-dom";


function useWindowSize(){
  const [size,setSize] = useState([window.innerHeight,window.innerWidth]);
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerHeight,window.innerWidth]);
    };
    window.addEventListener("resize",handleResize);
    return () => {
      window.removeEventListener("resize",handleResize);
    };
  }, []);
  return size;
}



function Welcome () {

  const [height,width] = useWindowSize();

   
  const history =useHistory();

  const [data, setData] = useState({
    email: "",
  });
  const { email } = data
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }


  return (
    <Welcome1>
      <img className="bacimg22" src={mainbac} />
      <div className="welcomecontent">
        <h1
          style={{
            fontSize: "60px",
            fontWeight: "normal",
            marginBottom: "50px",
            color: "#007aff",
          //  textAlign:"center",
          }}
        >
          Your online wardrobe
        </h1>
      
        <p style={{ width: "400px", fontSize: "24px"}}>
          We employ both 3D models and 2D images to give an immersive try-on
          experience, as well as, provide best fit recommendations using data
          generated by our try-on features.
        </p>
      <form  >
       <div className="input_container" style={{height:"40px",borderRadius:"25px",border:"3px solid black",display:"flex",width:"470px",marginRight:"10px"}}>
       <input type="text"  
              name="email"
                value={email}
                onChange={handleChange}
                required
        placeholder="enter your email...." style={{height:"33px",width:"350px",borderRadius:"25px",border:"3px solid white",margin:"0px",outline:"none",marginLeft:"8px"}} />
       <button onClick={() =>history.push({
         pathname:`/waitlist`,
         state:{email},
       })} 
       style={{height:"40px",borderRadius:"30px",width:"150px",margin:"0px",backgroundColor:"#007aff",border:"none"}} ><a href="#" style={{textDecoration:"none",color:"white"}}>Join Waitlist</a></button>
       </div>
       </form>
        <div className="downbtnsparent">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=in.co.tvish.tryon"
            >
              <div className="downbtns">
                <img
                  style={{
                    width: "15px",
                    height: "20px",
                    position: "relative",
                    top: "3px",
                    right: "2px",
                  }}
                  src={playstoreicon}
                />
                Download From Playstore
              </div>
            </a>
            <div onClick={() => alert("Coming Soon...")} className="downbtns">
              <img
                style={{
                  width: "15px",
                  height: "20px",
                  position: "relative",
                  top: "3px",
                  right: "2px",
                }}
                src={appstoreicon}
              />
              Download From Appstore
            </div>
          </div>
      </div>
      <video
      // className="video_auto"
      controls="controls"
      src={video}
    style={{ height:"300px" }}
    autoPlay={true}
    loop
    muted
  />
 
    </Welcome1>
  );
}

export default Welcome;

const Welcome1 = styled.div`
  color: black;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding-top: 11.5%;
  margin-right: 5%;
  .bacimg22 {
    position: absolute;
    width: 100vw;
    top: -50px;
    z-index: -10;
  }
  .input_container {
   display:flex;
   justify-Content:flex-end;
   height:"40px";
   borderRadius:"25px";
   border:"3px solid black";
   width:"480px";
   marginRight:"10px"
  }
  .welcomebtn1 {
    padding: 10px;
    color: white;
    background: #007aff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 120px;
    text-align: center;
    display: inline-block;
    font-size: 18px;
    margin: 15px;
    margin-left: 0;
    border: 2px solid #007aff;
    cursor: pointer;
    transition: 0.3s ease-out;
  }
  .welcomebtn1:hover {
    color: #007aff;
    background: white;
  }
  .welcomebtn2 {
    background: #ffffff;
    border-radius: 5px;
    width: 140px;
    text-align: center;
    display: inline-block;
    border: 2px solid #007aff;
    margin: 15px;
    color: #007aff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: 0.3s ease-out;
    p {
      padding: 10px;
      font-size: 18px;
      margin: 0;
    }
    cursor: pointer;
  }
  .downbtns {
    display: inline-block;
    // align-items: center;
    // justify-content: space-around;
    background: rgb(0, 122, 255);
    color: white;
    margin: 10px;
    padding: 10px 15px;
    border-radius: 10px;
    cursor: pointer;
     ${'' /* width: 195px; */}
  }
  .downbtnsparent {
    display: inline-block;
  }
  .welcomebtn2:hover {
    background: #007aff;
    color: white;
  }
  @media screen and (max-width: 480px) {
    padding-top: 25%;
    .welcomecontent {
      alignItems:center;
      margin-left: 30%;
    }
    ${'' /* .video_auto{
      width:60%
    } */}
    
  }
  @media screen and (max-width: 500px) {
    .downbtnsparent {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
    }
  }
  @media screen and (max-width: 510px) {
    padding-top: 25%;
    .welcomecontent {
      margin-left: 10%;
    }
  }
  @media screen and (max-width: 1100px) {
    .bacimg22 {
      display: none;
      ${'' /* marginLeft:10px;
      marginRight:10px; */}
    }
  }
`;
