import React, { useState, useLayoutEffect, useEffect } from "react";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo1 from "../images/tvish logo.png";
import { Link, useHistory } from "react-router-dom";
import profileimg1 from "../images/Profile.svg";
import profileimg2 from "../images/Profileblue.svg";
import navheart from "../images/heart_outline1.svg";
import navbookmark from "../images/Vector1.svg";

//auth and db firebase imports
import { db } from "../firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
} from "firebase/firestore/lite";
import {
  auth,
  signInWithEmailAndPassword1,
  signInWithGoogle1,
  logout,
} from "../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import { updateDoc, deleteDoc, doc } from "firebase/firestore/lite";

function Navbar() {
  const [isMobile, setIsMobile] = useState(false);
  const [profileimg, setprofileimg] = useState(profileimg1);

  const changeiconprof = (vi) => {
    if (profileimg == profileimg1 || profileimg == profileimg2) {
      if (vi == 1) {
        setprofileimg(profileimg2);
      } else {
        setprofileimg(profileimg1);
      }
    }
    if (vi == 1) {
      const list = document.querySelector(".submenulist");
      if (list) {
        list.classList.remove("hidden3");
      }
    } else {
      const list = document.querySelector(".submenulist");
      if (list) {
        list.classList.add("hidden3");
      }
    }
  };

  const clickHandler = () => {
    setIsMobile(!isMobile);
  };

  //loginorprofile starts
  const history = useHistory();
  const [user, loading, error] = useAuthState(auth);

  const [islogin, setislogin] = useState(false);
  useEffect(() => {
    if (!islogin) {
      setprofileimg(profileimg1);
    }
  }, [islogin]);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      setislogin(false);
      return;
    }
    if (user) {
      setislogin(true);
    }
    if (error) {
      setislogin(false);
    }
  }, [user, loading]);

  const [name, setName] = useState("");
  const [firstnametoshow, setfirstnametoshow] = useState("");
  const fetchUserName = async () => {
    try {
      const userscollectionref22 = collection(db, "usersdatafromwebsite");
      // const query = await db
      //   .collection("usersdatafromwebsite")
      //   .where("uid", "==", user?.uid)
      //   .get();
      const query1 = query(userscollectionref22, where("uid", "==", user?.uid));
      // const data = await query1.docs[0].data();
      const dataSnapshot = await getDocs(query1);
      setName(dataSnapshot.docs[0].data().name);
      var firstnametoshow1 = dataSnapshot.docs[0].data().name;
      firstnametoshow1 = firstnametoshow1.split(" ");
      firstnametoshow1 = firstnametoshow1[0];
      setfirstnametoshow(firstnametoshow1);
      if (dataSnapshot.docs[0].data().authProvider === "google") {
        setprofileimg(dataSnapshot.docs[0].data().photo);
      }
      setislogin(true);
      // const data = await getDocs(collectionRef);
      // setproducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  };
  useEffect(() => {
    if (loading) return;
    if (!user) {
      setislogin(false);
    }
    if (user) {
      console.log(user);
      fetchUserName();
    }
  }, [user, loading]);
  useEffect(() => {
    if (name === "") {
      fetchUserName();
    }
  }, [name]);

  return (
    <Navbar1 style={{height:"50px"}}>
      <Link to="/">
        <img
          style={{ width: "90px", height: "50px" }}
          src={logo1}
        />
      </Link>
      <div style={{ display: "flex" }}>
        <ul className={isMobile ? "navfeat active" : "navfeat"}>
          
          <li className="lists">
            <Link to="/" className="nav-link">
             Home
            </Link>
          </li>
           {/* <li className="lists">
            <Link to="/aboutus" className="nav-link">
              Shop
            </Link>
          </li>  */}
           <li className="lists">
            <Link to="/team" className="nav-link">
              Team
            </Link>
          </li>
          <li className="lists">
            <Link to="/getintouch" className="nav-link">
              Contact Us
            </Link>
          </li> 
        </ul>
      </div>
      {!islogin && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            onClick={() =>
              alert(
                "We are Sorry! you need to first have an account for using Wishlist."
              )
            }
            // src={navheart}
          />
          {/* <img src={navbookmark} /> */}
          <ul >
            <li className="lists">
              <Link
                onMouseEnter={() => {
                  changeiconprof(1);
                }}
                onMouseLeave={() => {
                  changeiconprof(2);
                }}
                to="/waitlist"
                className="nav-link2"
              >
                {/* <img src={profileimg} /> */}
               Join waitlist
              </Link>
            </li>
          </ul>
        </div>
      )}
      {islogin && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link to="/wishlistpage">
            {/* <img src={navheart} /> */}
          </Link>
          {/* <img onClick={logout} src={navbookmark} /> */}
          <ul style={{ paddingLeft: "0" }}>
            <li className="lists">
              <a
                onMouseEnter={() => {
                  changeiconprof(1);
                }}
                onMouseLeave={() => {
                  changeiconprof(2);
                }}
                className="nav-link2"
                // onClick={() => alert(name)}
                // onClick={logout}
              >
                <img
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    marginRight: "5px",
                  }}
                  src={profileimg}
                />
                {firstnametoshow}
                <ul className="submenulist hidden3">
                  <li>Settings</li>
                  <li
                    onClick={() => {
                      logout();
                      setislogin(false);
                    }}
                  >
                    Logout
                  </li>
                </ul>
              </a>
            </li>
          </ul>
        </div>
      )}
      <div className="mobile-menu-icon" onClick={clickHandler}>
        {isMobile ? (
          <CloseIcon
            style={{
              cursor: "pointer",
              width: "40px",
              height: "40px",
              border: "1px solid white",
              borderRadius: "10px",
            }}
          />
        ) : (
          <MenuIcon
            style={{
              cursor: "pointer",
              width: "40px",
              height: "40px",
              border: "1px solid white",
              borderRadius: "10px",
            }}
          />
        )}
      </div>
    </Navbar1>
  );
}

export default Navbar;

const Navbar1 = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // padding-left: 80px;
  // padding-right: 30px;
  padding: 10px 50px;
  position: fixed;

  border-bottom: 2px solid #50505026;
  top: 0;
  width: 95%;
  z-index: 1000;
  .navfeat {
    display: flex;
  }
  .lists {
    list-style: none;
    margin: 25px;
    font-size: 20px;
    font-weight: 600;
    line-height: 34.05px;
    color: #1938a7;
  }

  .mobile-menu-icon {
    display: none;
  }
  .nav-link {
    color: rgba(80, 80, 80, 0.5);
    text-decoration: none;
    // padding: 1rem;
    // padding: 5px 10px;
    // border: 3px solid white;
    // border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 18px;
  }
  .nav-link:hover {
    // border: 3px solid #007aff;
    // color: white;
    // background: #007aff;
    // border-radius: 5px;
    color: black;
  }
  .nav-link1 {
    color: #007aff;
    text-decoration: none;
    // padding: 1rem;
    // padding: 5px 10px;
    // border: 3px solid white;
    // border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 18px;
    border-bottom: 2px solid #007aff;
  }
  // .nav-link1:hover {
  //   border: 3px solid #007aff;
  //   color: white;
  //   background: #007aff;
  //   border-radius: 5px;
  // }
  .nav-link2 {
    color: white;
    background: #007aff;
    text-decoration: none;
    // padding: 1rem;
    padding: 4px 15px;
    border: 3px solid #007aff;
    border-radius: 5px;
    transition: all 300ms ease-out;
    font-size: 19px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-around;
    width:105px;
    margin:2px
   
  }
  .nav-link2:hover {
    border: 3px solid #007aff;
    color: #007aff;
    background: white;
    border-radius: 5px;
    img {
      color: #007aff;
    }
  }
  .submenulist {
    position: absolute;
    top: 100px;
    right: 80px;
    list-style: none;
    li {
      background-color: #007aff;
      color: white;
      padding: 3px 20px;
      // border-radius: 5px;
      border: 1px solid white;
      transition: 0.3s ease-out;
      cursor: pointer;
    }
    li:hover {
      background-color: white;
      color: #007aff;
      border: 1px solid #007aff;
    }
  }
  .hidden3 {
    display: none;
  }
  @media screen and (max-width: 1111px) {
    ${'' /* padding: 15px 15px;
    height: 100px;
    padding-bottom: 0px; */}
    .log {
      display: flex;
      position: absolute;
      top: 15px;
      left: 10px;
      padding-right:10px;
    }
   

    .navfeat {
      position: fixed;
      top: 50px;
      left: -100%;
      flex-direction: column;
      align-items: center;
      background-color: #242424;
      width: 100%;
      height: 100%;
      padding: 1.6rem 0;
      transition: all 300ms ease-in-out;
      z-index: 100;
    }
    .navfeat.active {
      left: 0%;
      transition: all 300ms ease-in-out;
    }
    .lists {
      color: white;
      width: 100%;
      text-align: center;
      

    }
    .nav-link {
      font-size: 1.8rem;
      width: 100%;
      color: white;
      border: 3px solid #242424;
      marginBottom:18px;
      marginRight:0px;
      marginLeft:151px;
    }
    .nav-link1 {
      font-size: 1.8rem;
      width: 100%;
      color: white;
      border: 3px solid #242424;
    }
    .mobile-menu-icon {
      display: block;
      padding-right:25px;
    }
    .nav-link2 {
      width: 120px;
      marginBottom:18px;
      marginRight:0px;
      marginLeft:151px;
      padding:2px;
      padding-bottom: 2pxpx;
    }
    .submenulist {
      right: 30%;
    }
  }
`;
