import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import playstoreicon from "../images/google-play.png";
import appstoreicon from "../images/app-store.png";
//landing page footer using bootstrap, it is mobile responsive
function Footer() {
  return (
    <div>
      <Footer1>
        <div className="footer11">
          <p style={{ fontSize: "30px", marginBottom: "15px" }}>TVISH</p>
          <p>Your Online Wardrobe</p>
          <div
            style={{
              display: "flex",
              marginTop: "15px",
              alignItems: "center",
            }}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/tvish"
            >
              <i
                style={{
                  backgroundColor: "white",
                  padding: "15px",
                  borderRadius: "50%",
                  color: "#757cff",
                  marginRight: "10px",
                }}
                class="fab fa-linkedin-in"
              ></i>
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/tvish_ar/"
            >
              <i
                style={{
                  backgroundColor: "white",
                  padding: "15px",
                  paddingLeft: "16px",
                  borderRadius: "50%",
                  color: "#757cff",
                  marginRight: "10px",
                }}
                class="fa fa-instagram"
              ></i>
            </a>
          </div>
        </div>
        <div className="footer1">
          <p
            style={{
              fontSize: "30px",
              textAlign: "center",
              marginBottom: "15px",
            }}
          >
            Contact us
          </p>
          <p
            style={{
              marginTop: "10px",
              display: "flex",
            }}
          >
            <EmailOutlinedIcon /> Support@tvish.co.in
          </p>
        </div>
      </Footer1>
      <Footer22>
        <hr
          style={{
            backgroundColor: "white",
            width: "80%",
            textAlign: "center",
            height: "2px",
            margin: "0 auto",
            padding: "0",
          }}
        />
        <div className="rights">
          <h4 style={{ color: "white" }}>
            All rights reserved, Built in INDIA
          </h4>
          <div className="downbtnsparent">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=in.co.tvish.tryon"
            >
              <div className="downbtns">
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "relative",
                    top: "3px",
                    right: "2px",
                  }}
                  src={playstoreicon}
                />
                Download From Playstore
              </div>
            </a>
            <div onClick={() => alert("Coming Soon...")} className="downbtns">
              <img
                style={{
                  width: "20px",
                  height: "20px",
                  position: "relative",
                  top: "3px",
                  right: "2px",
                }}
                src={appstoreicon}
              />
              Download From Appstore
            </div>
          </div>
        </div>
      </Footer22>
    </div>
  );
}
export default Footer;

const Footer1 = styled.div`
  background: #757cff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 50px;

  * {
    margin: 0;
  }
  .footer1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin-top: 50px;
    margin-bottom: 50px;
    p {
      color: white;
    }
    h1 {
      color: white;
    }
  }
  .footer11 {
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 50px;
    margin-bottom: 50px;
    p {
      color: white;
    }
    h1 {
      color: white;
    }
  }
  @media screen and (max-width: 617px) {
    .footer11 {
      // margin-left: 15%;
      align-items: center;
    }
  }
  // @media screen and (max-width: 520px) {
  //   width: 520px;
  //   // display: inline-block;
  // }
`;

const Footer22 = styled.div`
  background-color: #757cff;
  height: 100px;
  width:100%;
  // padding-bottom: 100px;
  * {
    margin: 0;
  }

  .rights {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #757cff;
    color: white;
  }
  .downbtns {
    display: inline-block;
    // align-items: center;
    // justify-content: space-around;
    background: white;
    color: #757cff;
    margin: 10px;
    padding: 10px 15px;
    border-radius: 10px;
    cursor: pointer;
    // width: 150px;
  }
  .downbtnsparent {
    display: inline-block;
  }
  // @media screen and (max-width: 500px) {
  //   width: 500px;
  //   // display: inline-block;
  // }
  @media screen and (max-width: 500px) {
    .downbtnsparent {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
    }
  }
`;
