import React, { useState,useEffect } from "react";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { useHistory } from "react-router-dom";




toast.configure()

function Waitlist() {
  const location = useLocation();

  useEffect(() => {
     console.log(location.pathname); // result: '/secondpage'
     console.log(location.state); // result: 'some_value'
  }, [location]);

  let str;
  function replace(key,val){
    if(typeof val === 'string')
    {
      str=val;
      return 'STRING';
    }
    else
    return val;
  }
  const  em=JSON.stringify(location.state,replace);
  console.log(em);
  // JSON.stringify(location.state,['email']),
  const [data, setData] = useState({
    typ:"join_waitlist",
    name: "",
    email:str,
  });
  const { typ,name, email } = data
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  

  //this function is used if we want to show notifiction only
 const notify = () => {
   toast.success( 'You are successfully joined waitlist!',{position:toast.POSITION.TOP_CENTER});
 }
  const warnify = () => {
    toast.warn('EveryField is compulsory!',{position:toast.POSITION.TOP_CENTER})

  }


 
  const handleSubmit = async e => {
    e.preventDefault();

    try {
      await fetch("https://v1.nocodeapi.com/tvish/google_sheets/HxhFjccDQVKbhcXE?tabId=Sheet1", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify([[typ,name, email, new Date().toLocaleDateString()]])
      });
      setData({ ...data, name: '', email: ' ' });
      history.push({
        pathname:`./`
      })
    } catch (err) {
      console.log(err)
    }

  };

  const history =useHistory();


  return (


    <Waitlist1>
      <Navbar id="1" />
      <div className="container1" style={{ margin: "65px" ,marginTop:"120px"}} >

        <div className="box">

          <div className="join">
            <h2 className="join">Join Waitlist</h2>
            {/* <p>{JSON.stringify(this.props.location.state.emai)}</p> */}
          </div>

          <div className="form">
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">Your Name</label><br />
              <input
                type="text"
                style={{ height: "30px", width: "300px", marginBottom: "20px", marginTop: "20px", marginLeft: "2px" }}
                id="name"
                // autoComplete="off"
                name="name"
                value={name}
                onChange={handleChange}
                required
              /><br />

              <label htmlFor="email">Email</label><br />
              <input
                type="text"
                style={{ height: "30px", width: "300px", marginBottom: "20px" }}
                id="email"
                // autoComplete="off"
                name="email"
                value={email}
                onChange={handleChange}
                required
              /><br />

              {/* <label htmlFor="message">What are you?</label><br />
              <input
                type="text"
                style={{ height: "30px", width: "300px", marginBottom: "20px" }}
                id="message"
                name="message"
                value={message}
                onChange={handleChange}
                required
              /><br /> */}
             
              <button  onClick={()=>{ 
                if(name&&email){
                  notify()
                }
                else
                warnify()
                 }}  
              style={{ fontsize: "20px", color: "white", height: "40px", width: "100%", marginBottom: "20px", backgroundColor: "#3498DB", border: "none" }} 
              type="submit">Submit
              </button>
              

              
            </form>
          </div>

        </div>
      </div>
      <div style={{ width: "100%" }}>
        <Footer />
      </div>
    </Waitlist1>
  );
}

export default Waitlist;

const Waitlist1 = styled.div`
  width: 100%;
  height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content:space-between;
  flex-wrap: wrap;
 
 

  .container1{
    ${'' /* marginTop: 700px; */}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* border: 2px solid red; */
    padding: 20px;
}


.join{
    /* border-bottom: 2px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3498DB;
    height: 60px;
    font-family: 'IBM Plex Sans Thai Looped', sans-serif;
    color: white;
    width: 100%;
    /* border: 2px solid orange; */
}
.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding: 20px;
    color: #4A4A4A;
    font-weight: bold;
    /* border: 2px solid blue; */
    font-family: 'IBM Plex Sans Thai Looped', sans-serif;
}

.box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 35%;
    /* border: px solid grey; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* padding: 10px; */
}


@media (min-width:360px) {
  backgroundColor:red;
    .box{
        width:95%;
        ${'' /* border:5px solid orange; */}
        ${'' /* backgroundColor:red; */}
    }
    input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 2px solid grey;
  border-radius: 4px;
  box-sizing: border-box;
  height:40px;
}
}
@media (min-width:768px) {
    .box{
        width:50%
    }
}
@media (min-width:900px) {
    .box{
        width:50%
    }
}
@media (min-width:1050px) {
    .box{
        width:30%
    }
}
  
@media screen and (max-width: 480px) {
    .box1{
      ${'' /* border: 2px solid black; */}
      margin-right:190px;
      width: 100vw;
    }
   
  }
  @media screen and (max-width: 1010px) {
      height:750px;
      .getheaddiv{
          display:block;
          margin-top:0;
          height:5px;
          z-index:100;
      }
      .gethead{
          margin-top:0;
          font-size:45px;
          padding-top:15px;
          padding-bottom:15px;
          z-index:100;
      }
      .contactform{
          width:95%;
          margin-right:0px;
          // height:640px;
          // margin-top:0;
          margin-top:150px;
      }
  }
`;
