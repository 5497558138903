import logo from "./logo.svg";
import "./App.css";
import React, { useState } from "react";
import Home from "./components/Home.js";
import Team from "./components/Team.js";
import Ecommerce from "./components/Ecommerce";
import Ecommercesignup from "./components/Ecommercesignup";
import Ecommercemain from "./components/Ecommercemain";
import Product from "./components/Product";
import Featurepage from "./components/Featurepage";
import Wishlistpage from "./components/Wishlistpage";
import Waitlist from "./components/Waitlist";
import Getintouch from "./components/Getintouch";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { auth } from "./firebase-config";

function App() {
 
  return (
    <div>
      <Router>
        <Switch>
        <Route exact path="/aboutus">
            <Ecommerce />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/team">
            <Team />
          </Route>
          <Route exact path="/product/:productid">
            <Product />
          </Route>
          <Route exact path="/featurepage">
            <Featurepage />
          </Route>
          <Route exact path="/wishlistpage">
            <Wishlistpage />
          </Route>
          <Route exact path="/waitlist">
            <Waitlist />
          </Route>
          <Route exact path="/getintouch">
            <Getintouch />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
