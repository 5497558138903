import React, { useState, useContext } from "react";
import styled from "styled-components";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
function Footer2() {
  return (
    <Footer1>
      <div className="footer1" style={{ width: "300px" }}>
        <p
          style={{
            fontSize: "30px",
            marginBottom: "15px",
            textAlign: "center",
          }}
        >
          Downloads:
        </p>
        <p style={{ textAlign: "center" }}>Appstore</p>
        <p style={{ textAlign: "center" }}>Playstore</p>
      </div>
      <div className="footer1" style={{ width: "300px" }}>
        <p
          style={{
            fontSize: "30px",
            marginBottom: "15px",
            textAlign: "center",
          }}
        >
          Resources:
        </p>
        <p style={{ textAlign: "center", marginBottom: "10px" }}>Home</p>
        <p style={{ textAlign: "center", marginBottom: "10px" }}>Usecase</p>
        <p style={{ textAlign: "center", marginBottom: "10px" }}>Media</p>
        <p style={{ textAlign: "center", marginBottom: "10px" }}>Seller</p>
      </div>

      <div className="footer1" style={{ width: "300px" }}>
        <p
          style={{
            fontSize: "30px",
            marginBottom: "15px",
            textAlign: "center",
          }}
        >
          Follow us:
        </p>
        <p style={{ textAlign: "center", marginBottom: "10px" }}>Instragram</p>
        <p style={{ textAlign: "center", marginBottom: "10px" }}>Facebook</p>
        <p style={{ textAlign: "center", marginBottom: "10px" }}>Twitter</p>
        <p style={{ textAlign: "center", marginBottom: "10px" }}>LinkedIn</p>
      </div>

      <div className="footer1" style={{ width: "300px" }}>
        <p
          style={{
            fontSize: "30px",
            textAlign: "center",
            marginBottom: "15px",
          }}
        >
          Contact us:
        </p>

        <p
          style={{
            textAlign: "center",
          }}
        >
          Support@tvish.co.in
        </p>
        <p style={{ textAlign: "center" }}>+91-7975632362</p>
      </div>
    </Footer1>
  );
}

export default Footer2;

const Footer1 = styled.div`
  background: #007aff;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 50px;
  .footer1 {
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 700px) {
    justify-content: space-around;
    padding-top: 10px;
    .footer1 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 1030px) {
    // margin-top: 200px;
  }
`;
